const { range } = require("lodash");

const orgtree = {
  1: {
    name: "Super Admin",
    dependencies: {
      1: [],
    },
    type: 1,
  },
  2: {
    name: "State Admin",
    dependencies: {
      1: ["states"],
      2: ["states"],
    },
    type: 2,
  },
  3: {
    name: "Region Admin",
    dependencies: {
      1: ["states", "regions"],
      2: ["regions"],
      3: ["regions"],
    },
    type: 3,
  },
  4: {
    name: "Association Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 4,
  },
  5: {
    name: "Club Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs"],
      2: ["regions", "associations", "clubs"],
      3: ["associations", "clubs"],
      4: ["clubs"],
      5: ["clubs"],
    },
    type: 5,
  },
  6: {
    name: "Team Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs", "teams"],
      2: ["regions", "associations", "clubs", "teams"],
      3: ["associations", "clubs", "teams"],
      4: ["clubs", "teams"],
      5: ["teams"],
      6: ["teams"],
    },
    type: 6,
  },
  50: {
    name: "Gameday Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 50,
  },
  51: {
    name: "Elite Gameday Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 51,
  },
  98: {
    name: "Game Dev Region Admin",
    dependencies: {
      1: ["game-dev-regions"],
      98: ["game-dev-regions"],
    },
    type: 98,
  },
  99: {
    name: "Program Admin",
    dependencies: {
      1: ["game-dev-regions"],
      98: ["game-dev-regions"],
    },
    type: 99,
  },
  101: {
    name: "Elite Team Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs", "teams"],
      2: ["regions", "associations", "clubs", "teams"],
      3: ["associations", "clubs", "teams"],
      4: ["clubs", "teams"],
      5: ["teams"],
      6: ["teams"],
    },
    type: 101,
  },
  102: {
    name: "Elite Partner",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 102,
  },
  103: {
    name: "Team CMO",
    dependencies: {
      1: ["states", "regions", "associations", "clubs", "teams"],
      2: ["regions", "associations", "clubs", "teams"],
      3: ["associations", "clubs", "teams"],
      4: ["clubs", "teams"],
      5: ["teams"],
      6: ["teams"],
    },
    type: 103,
  },
  104: {
    name: "Association CMO",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 104,
  },
  105: {
    name: "Club Program Admin",
    dependencies: {
      1: ["clubs"],
      5: ["clubs"],
    },
    type: 105,
  },
  418: {
    name: "Developer",
    dependencies: {},
    type: 418,
  },
};

const roles = {
  superAdmin: 1,
  stateAdmin: 2,
  regionAdmin: 3,
  associationAdmin: 4,
  clubAdmin: 5,
  teamAdmin: 6,
  gamedevregionAdmin: 98,
  programAdmin: 99,
  teamAdminElite: 101,
  clubProgramAdmin: 105,
  developer: 418,
};

const programStatuses = [
  "In Progress",
  "Completed",
  "Cancelled - Low Numbers",
  "Cancelled - Venue Availability",
  "Cancelled - Wet Weather",
  "Cancelled - Other",
];

const clearancePermitStatuses = [
  { name: "Pending", type: "pending" },
  { name: "Approved", type: "approved" },
  { name: "Declined", type: "declined" },
  { name: "Awaiting Participant Registration", type: "Awaiting participant registration" },
];

const nationals = {
  TFA: 31,
  NRL: 32,
};

const errormsg = {
  char_length: "Please keep it under 10 characters",
  invalidUrl: "Website URLs must follow this format: https://example.com",
  functionality_not_available: "This functionality is currently unavailable",
  phone: "Please enter a valid number",
  emails: "Please enter valid emails",
  email: "Please enter a valid email",
  postCode: "Please enter a valid postal code",
  suburb: "Please enter a valid suburb description",
  bad_movement: "Incomplete movement event",
  myactive_role: "Error deleting your current role",
  add_role: "Error adding a user role",
  delete_role: "Error deleting user role",
  delete_role_mymedical: 'You do not have permission to remove this MyMedical role. If you believe this is a mistake please contact your administrator.',
  pos_number: "Invalid position number",
  order_number: "Invalid order number",
  max_min: "Maximum age is lower than minimum age",
  price_decimal: "Price has more than 2 decimal places",
  age_overlap: "Overlapping age range",
  min_age: "Invalid minimum age",
  max_age: "Invalid maximum age",
  date_from: "Invalid date from",
  date_to: "Invaid date to",
  date_from_to: "Overlapping date range",
  gte_age: "Age to must be at least equal to age from",
  gte_date: "Date to must be at least equal to age from",
  price_overlap: "Overlapping prices",
  price_invalid: "Invalid price",
  select_option: "Please select an option",
  input_option: "Field is required",
  discount_amount: "Discount Amount must be a positive number",
  duplicate_team: "Home & Away team cannot be the same",
  draw_competitionpublished: "Draw for this competition has already been published",
  draw_compnovenue: "Compeititon has no venue",
  draw_alreadybuilt: "Draw for this competition already exists",
  draw_matches: "Matches already exist for this competition",
  draw_notenoughteam: "3 or more teams are required",
  draw_notenoughteam_in_pools: "3 or more teams are required in pools",
  draw_missingroundate: "Please complete all round dates",
  draw_finalneedmoreteam: "Not enough teams for the finals template selected",
  draw_invalidrounddatetime: "Invalid round date",
  draw_sameteam: "Home & Away team cannot be the same, except TBA",
  draw_tbabyematch: "Home & Away team cannot be TBA and BYE at the same time",
  regiproduct_invalidpercetageamount: "Discount amount must be under 100 for percentage type",
  regiproduct_invaliddiscountamount: "Invalid discount amount",
  regiproduct_invaliddiscountname: "Invalid discount name",
  regiproduct_overlap: "Overlapping discount date range",
  regiproduct_saleableitemsamename: "Found other items with same name",
  regiproduct_invaliddiscountfrom: "Invalid discount from date",
  regiproduct_invalidfamilydiscountfrom: "Invalid Regos From",
  regiproduct_invalidfamilydiscountto: "Invalid Regos To",
  regiproduct_role_exists_with_age: "Please update existing products with age dates",
  image_format: "Only jpeg, jpg and png format are supported ",
  verification_confirm: "Please first check confirm box in order to verify this record",
  nrlid: "Invalid NRLID",
  same_position: "Player already in position",
  same_player: "Player on and off cannot be the same",
  dob_from: "Invalid date of birth from",
  dob_to: "Invalid date of birth to",
  gte_dob: "The To must be at least equal to the From",
  dob_overlap: "Overlapping dates of birth",
  gte_positive_zero: "Value must be a positive number or at least equal to 0",
  player_unavailable: "This player is currently unavailable",
  comp_overlapp: "Overlapping competition",
  two_week_ranges: "The period should not be longer than 2 weeks",
  one_week_ranges: "The period should not be longer than 7 days",
  akvBusinessId: "Invalid BusinessId",
  akvLocationId: "Invalid LocationId",
};

const ageLevels = [
  { name: "Under 4", min: 3, max: 4 },
  { name: "Under 5", min: 4, max: 5 },
  { name: "Under 6", min: 5, max: 6 },
  { name: "Under 7", min: 6, max: 7 },
  { name: "Under 8", min: 7, max: 8 },
  { name: "Under 9", min: 8, max: 9 },
  { name: "Under 10", min: 9, max: 10 },
  { name: "Under 11", min: 10, max: 11 },
  { name: "Under 12", min: 11, max: 12 },
  { name: "Under 13", min: 12, max: 13 },
  { name: "Under 14", min: 13, max: 14 },
  { name: "Under 15", min: 14, max: 15 },
  { name: "Under 16", min: 15, max: 16 },
  { name: "Under 17", min: 16, max: 17 },
  { name: "Under 18", min: 17, max: 18 },
  { name: "Seniors", min: 18, max: 99 },
  { name: "Masters", min: 34, max: 99 },
  { name: "All Ages", min: 4, max: 99 },
];

const duplicateAgeLevels = [
  { name: "Under 4", min: 3, max: 4 },
  { name: "Under 5", min: 4, max: 5 },
  { name: "Under 6", min: 5, max: 6 },
  { name: "Under 7", min: 6, max: 7 },
  { name: "Under 8", min: 7, max: 8 },
  { name: "Under 9", min: 8, max: 9 },
  { name: "Under 10", min: 9, max: 10 },
  { name: "Under 11", min: 10, max: 11 },
  { name: "Under 12", min: 11, max: 12 },
  { name: "Under 13", min: 12, max: 13 },
  { name: "Under 14", min: 13, max: 14 },
  { name: "Under 15", min: 14, max: 15 },
  { name: "Under 16", min: 15, max: 16 },
  { name: "Under 17", min: 16, max: 17 },
  { name: "Under 18", min: 17, max: 18 },
  { name: "Under 19-24", min: 19, max: 24 },
  { name: "Under 25-30", min: 25, max: 30 },
  { name: "Under 31-36", min: 31, max: 36 },
  { name: "37+", min: 37, max: 99 },
];

const memberTypes = [
  {
    name: "Player Tackle",
    type: "player",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player Touch",
    type: "player-touch",
    playing: true,
    stateType: "club-league",
    tfa: true,
  },
  {
    name: "Player LeagueTag",
    type: "player-league-tag",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player League Touch",
    type: "player-league-touch",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player Representative",
    type: "player-representative",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player Masters",
    type: "player-masters",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player Social",
    type: "player-social",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Player School",
    type: "player-school",
    playing: true,
    stateType: "club-league",
    tfa: false,
  },
  {
    name: "Non-Participating Member",
    type: "non-participating-member",
    playing: false,
    stateType: "both",
    tfa: false,
  },
  {
    name: "Referee",
    type: "referee",
    playing: false,
    stateType: "referee",
    tfa: true,
  },
  {
    name: "Coach",
    type: "coach",
    playing: false,
    stateType: "both",
    tfa: true,
  },
  {
    name: "Volunteer",
    type: "volunteer",
    playing: false,
    stateType: "both",
    tfa: true,
  },
  {
    name: "Trainer",
    type: "trainer",
    playing: false,
    stateType: "club-league",
    tfa: false,
  },
];

const nonPlayerRoles = [
  {
    name: "Coach",
    type: "coach",
    memberType: "coach",
  },
  {
    name: "Assistant Coach",
    type: "assistant-coach",
    memberType: "coach",
  },
  {
    name: "Manager",
    type: "manager",
    memberType: "volunteer",
  },
  {
    name: "LeagueSafe",
    type: "trainer-1",
    memberType: "trainer",
  },
  {
    name: "League First Aid",
    type: "trainer-2",
    memberType: "trainer",
  },
  {
    name: "Level 1 Trainer",
    type: "trainer-3",
    memberType: "trainer",
  },
  {
    name: "Level 2 Trainer",
    type: "trainer-4",
    memberType: "trainer",
  },
  {
    name: "Chief (CMO)",
    type: "cmo",
    memberType: "trainer",
  },
  {
    name: "Assistant (AMO)",
    type: "amo",
    memberType: "trainer",
  },
];

const nonPlayerRolesTFA = [
  {
    name: "Coach",
    type: "coach",
    memberType: "coach",
  },
  {
    name: "Assistant Coach",
    type: "assistant-coach",
    memberType: "coach",
  },
  {
    name: "Manager",
    type: "manager",
    memberType: "volunteer",
  },
  {
    name: "Referee",
    type: "referee",
    memberType: "referee",
  },
];

const genders = ["Male", "Female", "All"];

const matchReports = [
  {
    nationals: [nationals.NRL],
    name: "International Single Team Sheet",
    value: "int-single-team",
    default: true,
  },
  {
    nationals: [nationals.NRL],
    name: "International Single Team - Opposition Summary",
    value: "int-single-team-opp-score",
  },
  {
    nationals: [nationals.NRL],
    name: "Junior Rugby League 6 - 9 Team Sheet",
    value: "junior-rugby-league-6-9",
  },
  {
    nationals: [nationals.NRL],
    name: "Junior Rugby League 10 - 12 Team Sheet",
    value: "junior-rugby-league-10-12",
  },
  {
    nationals: [nationals.NRL],
    name: "International – Both Teams",
    value: "int-both-teams",
  },
  { nationals: [nationals.TFA], name: "TFA Sign-on/Scorecard", value: "tfa-int-both-teams" },
  { nationals: [nationals.TFA], name: "TFA Scorecard", value: "tfa-score-cards" },
];

const matchStatus = [
  { name: "Pre Game", type: "pre-game" },
  { name: "In Progress", type: "in-progress" },
  { name: "Finalised", type: "final" },
  { name: "Forfeit", type: "forfeit" },
  { name: "Disputed", type: "disputed" },
  { name: "Washed Out", type: "washout" },
  { name: "Postponed", type: "postponed" },
];

const eliteGameStates = [
  { name: "Pre Game", type: 2 },
  { name: "First Half", type: 3 },
  { name: "Half Time", type: 4 },
  { name: "Second Half", type: 5 },
  { name: "Extra Time", type: 6 },
  { name: "Final", type: 8 },
  { name: "Postponed", type: 9 },
];

const matchStatusBulk = [
  { name: "Pre Game", type: "pre-game" },
  { name: "In Progress", type: "in-progress" },
  { name: "Final", type: "final" },
  { name: "Forfeit Home", type: "Forfeit Home" },
  { name: "Forfeit Away", type: "Forfeit Away" },
  { name: "Disputed", type: "disputed" },
  { name: "Washed Out", type: "washout" },
  { name: "Postponed", type: "postponed" },
];

const nrlScoring = [
  { name: "Try", type: 38, value: 4 },
  { name: "Conversion Successful", type: 1, value: 2 },
  { name: "Conversion Unsuccessful", type: 2, value: 0 },
  { name: "Penalty Goal Successful", type: 67, value: 2 },
  { name: "Penalty Goal Unsuccessful", type: 68, value: 0 },
  { name: "1pt Field Goal Successful", type: 1000287, value: 1 },
  { name: "1pt Field Goal Unsuccessful", type: 1000300, value: 0 },
  { name: "2pt Field Goal Successful", type: 1000288, value: 2 },
  { name: "2pt Field Goal Unsuccessful", type: 1000301, value: 0 },
  // migrating to 2 pt field goals
  // { name: "Field Goal Successful", type: 69, value: 1 },
  // { name: "Field Goal Unsuccessful", type: 70, value: 0 },
  { name: "Try Under Posts", type: 102, value: 5 },
  { name: "Penalty Try", type: 99, value: 4 }, // Sent as 38 to STATS
];

const interchanges = [
  { name: "Regular Interchange - counts", type: 10 },
  { name: "HIA Free Interchange", type: 11 },
  { name: "HIA Regular Play - free", type: 12 },
  { name: "HIA Return To Field", type: 13 },
  { name: "Sin Bin", type: 14 },
  { name: "Sin Bin Return To Field", type: 15 },
  { name: "Send Off", type: 16 },
  { name: "Player Off-field", type: 200 },
  { name: "Player On-field", type: 201 },
  { name: "Foul Play - Free Interchange", type: 202 },
  { name: "Foul Play - HIA Regular Play Free Interchange", type: 203 },
  {
    name: "HIA - Cleared To Return To Field within 15 minutes - free",
    type: 204,
  },
  {
    name: "HIA - Cleared To Return To Field outside 15 minutes - free",
    type: 205,
  },
  { name: "Foul Play Return - Free Interchange", type: 206 },
  {
    name: "HIA - Not Cleared To Return To Play - counts",
    type: 207,
  },
  {
    name: "HIA - Cleared however remaining on bench - counts",
    type: 208,
  },
];

const tfaScoring = [{ name: "Try", type: 20, value: 1 }];

const finalTemplates = [
  {
    type: "2 Team 1 Week",
    teamnum: 2,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Grand Final",
        matchup: "1 v 2",
      },
    ],
  },
  {
    type: "4 Team 2 Week",
    teamnum: 4,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Semi Finals",
        matchup: "1 v 4 SF1",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Semi Finals",
        matchup: "2 v 3 SF2",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Grand Final",
        matchup: "WSF1 v WSF2 GF",
      },
    ],
  },
  {
    type: "4 Team 3 Week",
    teamnum: 4,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Semi Finals",
        matchup: "3 v 4 SF1",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Semi Finals",
        matchup: "1 v 2 SF2",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Preliminary Final",
        matchup: "L2 v W1 PF",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Grand Final",
        matchup: "W2 v W3 GF",
      },
    ],
  },
  {
    type: "4 Team 4 Week",
    teamnum: 4,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "1st Semi Final",
        matchup: "3 v 4 SF1",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "2nd Semi Final",
        matchup: "1 v 2 SF2",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Preliminary Final",
        matchup: "L2 v W1 PF",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 4,
        "Round Display": "Grand Final",
        matchup: "W2 v W3 GF",
      },
    ],
  },
  {
    type: "5 Team 4 Week",
    teamnum: 5,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "4 v 5 EF",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Qualifying Finals",
        matchup: "2 v 3 QF",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "L2 v W1 SF1",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "1 v W2 SF2",
      },
      {
        Match: 5,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Preliminary Final",
        matchup: "L4 v W3 PF",
      },
      {
        Match: 6,
        "Round Type": "Final",
        "Round Number": 4,
        "Round Display": "Grand Final",
        matchup: "W4 v W5 GF",
      },
    ],
  },
  {
    type: "6 Team 3 Week",
    teamnum: 6,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "4 v 5 EF1",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "3 v 6 EF2",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "1 v W1 SF1",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "2 v W2 SF2",
      },
      {
        Match: 5,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Grand Final",
        matchup: "W3 v W4 GF",
      },
    ],
  },
  {
    type: "6 Team 4 Week",
    teamnum: 6,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "4 v 5",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "3 v 6",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "1 v 2",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "W1 v W2",
      },
      {
        Match: 5,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Preliminary Final",
        matchup: "L3 v W4",
      },
      {
        Match: 6,
        "Round Type": "Final",
        "Round Number": 4,
        "Round Display": "Grand Final",
        matchup: "W3 v W5",
      },
    ],
  },
  {
    type: "8 Team 4 Week",
    teamnum: 8,
    matches: [
      {
        Match: 1,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Qualifying Finals",
        matchup: "1 v 4 QF1",
      },
      {
        Match: 2,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Qualifying Finals",
        matchup: "2 v 3 QF2",
      },
      {
        Match: 3,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "5 v 8 EF1",
      },
      {
        Match: 4,
        "Round Type": "Final",
        "Round Number": 1,
        "Round Display": "Elimination Finals",
        matchup: "6 v 7 EF2",
      },
      {
        Match: 5,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "L1 v W3 SF1",
      },
      {
        Match: 6,
        "Round Type": "Final",
        "Round Number": 2,
        "Round Display": "Semi Finals",
        matchup: "L2 v W4 SF2",
      },
      {
        Match: 7,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Preliminary Finals",
        matchup: "W2 v W5 PF1",
      },
      {
        Match: 8,
        "Round Type": "Final",
        "Round Number": 3,
        "Round Display": "Preliminary Finals",
        matchup: "W1 v W6 PF2",
      },
      {
        Match: 9,
        "Round Type": "Final",
        "Round Number": 4,
        "Round Display": "Grand Final",
        matchup: "W7 v W8 GF",
      },
    ],
  },
];

const teamListStatus = [
  { name: "Mid Week", type: "mid-week" },
  { name: "24 Hour", type: "24h" },
  { name: "1 Hour", type: "1h" },
  { name: "Final", type: "final" },
];

const entityType = [
  { name: "Club League", type: "club-league" },
  { name: "Referee", type: "referee" },
];

const uploadURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/admin/logo/upload`;
const supportDocumentURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/admin/upload-supporting-document`;
const headshotUploadURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/admin/headshot/upload`;
const productUploadURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/admin/product/upload`;

const sortBy = ["Total Points", "Points Difference", "Percentage"];

const dispensationTypes = [
  { type: "18 months registration window" },
  { type: "Weight for age" },
  { type: "Over 40 clearance" },
  { type: "Play above (1) age group consent" },
  { type: "Play above (2) age groups consent" },
  { type: "Play below (1) age group consent" },
  { type: "Play below (2) age groups consent" },
];

const dispensationRules = [
  { rule: "Participate (1) age group above" },
  { rule: "Participate (2) age groups above" },
  { rule: "Participate (1) age group below" },
  { rule: "Participate (2) age groups below" },
];

const date = new Date();
const currentMonth = date.getMonth();
const currentSeason = currentMonth <= 10 ? date.getFullYear() : date.getFullYear() + 1;

// All nrl past and current seasons
const getCurrentSeasonArr = (cs = date.getFullYear()) => {
  const start = 2020;
  const end = cs;
  const range = [...Array(end - start + 1).keys()].map((x) => x + start);

  return range.sort((a, b) => b - a);
};
const mysidelineSeasons = getCurrentSeasonArr(currentSeason);
const allSeasons = range(currentSeason, 2000, -1).concat(1900);
// const historicSeasons = range(currentSeason - 1, 1997, -1).concat(1900);
const historicStatsSeasons = range(currentSeason, 1997, -1).concat(1900);
const historicSomeSeasons = range(currentSeason - 3, 1997, -1).concat(1900);
const historicSeasons = range(2019, 1997, -1).concat(1900);

const seasonTags = [
  { _id: 1, name: "Season 1" },
  { _id: 2, name: "Season 2" },
];

const states = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory",
].map((x) => ({ label: x, value: x }));

const wwcStatus = [
  { type: "active", name: "Active" },
  { type: "pending", name: "Pending" },
  { type: "expired", name: "Expired" },
  { type: "barred", name: "Barred" },
];

const autoApprovals = [
  { type: 1, name: "Inward Only" },
  { type: 2, name: "Outward Only" },
  { type: 3, name: "Both Inward and Outward" },
];

const registrationStatus = [
  { type: "all", name: "All" },
  { type: "active", name: "Active (Registered in current season)" },
  { type: "inactive", name: "Inactive (Not registered in current season)" },
];

const gamePeriods = [
  { name: "First Half", type: 1 },
  { name: "Second Half", type: 2 },
  { name: "Extra Time", type: 3 },
];

const compFeeTypes = [
  { name: "Team Pays", type: 1 },
  { name: "Player Pays", type: 2 },
];

const caseCharges = [
  { type: 38, name: "1.a Uses offensive or obscene language" },
  { type: 39, name: "1.b Enter the field of play without the approval of the Ground Manager" },
  { type: 40, name: "1.c Dispute the decision of a match official" },
  { type: 41, name: "1.d Assault or act with aggression to any person/s ." },
  {
    type: 1,
    name: "1.e Behave in anyway contrary to the NRL Code of conduct and/or the spirit of the game",
  },
  {
    type: 2,
    name: "1.f Behave in anyway which disturbs the enjoyment of the match/discredits the home club and/or league",
  },
  {
    type: 3,
    name: "1.g Act in such a way as to exhibit racial intolerance, by language or other conduct",
  },
  {
    type: 4,
    name: "1.h Refuse to accept the reasonable direction of the Ground Manager, or club, league, team officials",
  },
  {
    type: 5,
    name: "1.i No person/s may make comment or take actions that bring discredit to any person, Club or league through any social media outlet",
  },
  { type: 6, name: "15.1.a Trips, Kicks, Strikes" },
  { type: 7, name: "15.1.b Contact head/neck" },
  { type: 8, name: "15.1.c Drops Knees" },
  { type: 9, name: "15.1.d Dangerous Throw" },
  { type: 10, name: "15.1.e Deliberately & Continuously breaks the Laws of the Game" },
  { type: 11, name: "15.1.f Offensive or obscene language" },
  { type: 12, name: "15.1.g Disputes decision by match official" },
  { type: 13, name: "15.1.h Re-enters the field without permission" },
  { type: 14, name: "15.1.i Behaves contrary to the spirit of the game" },
  { type: 15, name: "15.1.j Deliberately obstructs an opponent who is not in possession" },
  { type: 16, name: "15.1.k Uses a shoulder charge on an opponent" },
  {
    type: 17,
    name: "15.1.l Applies any unnecessary pressure or twist including grapples, crushers or performs a chicken wing style hold on a player in possession.",
  },
  {
    type: 18,
    name: "15.1.m Forcefully spears at the legs of a player in possession, exposing him to unnecessary risk of injury.",
  },
  { type: 19, name: "Careless High Tackle" },
  { type: 20, name: "Contrary Conduct" },
  { type: 42, name: "Crusher Tackle" },
  { type: 50, name: "Dangerous Contact" },
  // { type: 21, name: "Dangerous Contact (Head/Neck)" },
  // { type: 37, name: "Dangerous Contact (Other)" },
  { type: 22, name: "Dangerous Throw" },
  { type: 23, name: "Detrimental Conduct" },
  { type: 24, name: "Disputing Decision" },
  { type: 25, name: "Dropping Knees" },
  { type: 26, name: "Intentional High Tackle" },
  { type: 27, name: "Kicking" },
  { type: 28, name: "Law Breaking" },
  { type: 29, name: "Obstructing" },
  { type: 30, name: "Offensive Language" },
  { type: 31, name: "Other - Breach of Constitution/By-Laws/Rules and Regulations" },
  { type: 32, name: "Reckless High Tackle" },
  { type: 33, name: "Re-entering Play" },
  { type: 34, name: "Shoulder Charge" },
  { type: 35, name: "Striking" },
  { type: 36, name: "Tripping" },
];

const compCasesTypes = [
  { type: 1, name: "Tribunal" },
  { type: 2, name: "Injury" },
  { type: 3, name: "Disciplinary" },
];

const yesNoOptions = [
  { option: "Yes -Observed", value: "Yes -Observed" },
  { option: "Yes -Reported", value: "Yes -Reported" },
  { option: "No", value: "No" },
];

const yesOrNo = [
  { option: "Yes", value: true },
  { option: "No", value: false },
];

const firstResponderRoleList = [
  { option: "League First Aid", value: "League First Aid" },
  { option: "Level 1 Sports Trainer", value: "Level 1 Sports Trainer" },
  { option: "Level 2 Sports Trainer", value: "Level 2 Sports Trainer" },
  { option: "CMO", value: "CMO" },
  { option: "AMO", value: "AMO" },
];

const chargeOutcomes = [
  "Early Guilty Plea",
  "Guilty",
  "Not Guilty",
  "Suspended Until Appears",
  "Withdrawn",
  "No Contest",
  "Downgrade to Grade 1",
  "Downgrade to Grade 2",
  "Downgrade to Grade 3",
  "Downgrade to Grade 4",
  "Upgrade to Grade 2",
  "Upgrade to Grade 3",
  "Upgrade to Grade 4",
  "Upgrade to Grade 5",
];
const natureOfInjuryOptions = [
  { option: "Bruise/Contusion", value: "Bruise/Contusion" },
  { option: "Bleeding", value: "Bleeding" },
  { option: "Cardiac Problem", value: "Cardiac Problem" },
  { option: "Breast or chest tissue injury", value: "Breast or chest tissue injury" },
  { option: "Dislocation/Subluxation", value: "Dislocation/Subluxation" },
  { option: "Fracture", value: "Fracture" },
  { option: "Heat-Related Illness", value: "Heat-Related Illness" },
  { option: "Inflammation/Swelling", value: "Inflammation/Swelling" },
  { option: "Respiratory Problem", value: "Respiratory Problem" },
  { option: "Skin Injury", value: "Skin Injury" },
  { option: "Strain", value: "Strain" },
  { option: "Sprain", value: "Sprain" },
  { option: "Suspected Spinal Injury", value: "Suspected Spinal Injury" },
  { option: "Suspected Concussion", value: "Suspected Concussion" },
  { option: "Other", value: "Other" },
];
const howPlayerInjuredList = [
  { option: "Non-tackle collision", value: "Non-tackle collision" },
  { option: "Non-contact - running/side stepping", value: "Non-contact - running/side stepping" },
  { option: "Foul Play", value: "Foul Play" },
  { option: "Making a tackle - tackler", value: "Making a tackle - tackler" },
  { option: "Getting tackled - ball carrier", value: "Getting tackled - ball carrier" },
  { option: "Other", value: "Other" },
];
const methodRemovedFromField = [
  "Walked",
  "Assisted",
  "Stretcher",
  "Ambulance",
  "Completed Match/Training",
];
const referredTo = ["Ambulance", "Doctor", "Hospital", "Physiotherapist", "Other"];

const playerSelfStateList = [
  { option: "No different", value: "No different" },
  { option: "Very different", value: "Very different" },
  { option: "Unsure", value: "Unsure" },
  { option: "Not applicable", value: "Not applicable" },
];

const immediateCare = [
  "None given",
  "CPR",
  "Rest, Ice, Compression, Elevation, Referral",
  "Immobilisation",
  "Dressing",
  "Sling/Splint",
  "Strapping/Taping",
  "Other",
];
const injuryAvailability = [
  { value: true, label: "Available" },
  { value: false, label: "Unavailable" },
];
const compCaseScenes = [
  { type: 1, name: "Match" },
  { type: 3, name: "Training" },
  { type: 2, name: "Other" },
];
const tribunalStatus = [
  "All",
  "Pending",
  "Charge Issued",
  "No Further Action Required",
  "Concerning Act",
];

const penaltyTypes = [
  "Warning",
  "Caution",
  "Fine",
  "Points",
  "Match",
  "Week",
  "Month",
  "Year",
  "Life",
];

const grassrootGameStates = [
  { name: "Period 1", type: 20 },
  { name: "Period 2", type: 21 },
  { name: "Period 3", type: 22 },
  { name: "Period 4", type: 23 },
  { name: "Extra Time", type: 24 },
  { name: "Final", type: 25 },
];
const grassrootGameStatesTypes = grassrootGameStates.map((s) => s.type);

const disabilityTypes = [
  "Vision Impairment",
  "Hearing Impairment",
  "Intellectual disability",
  "Autism",
  "Asperger's Syndrome",
  "Cerebral Palsy",
  "Spina Bifida",
  "Spinal Cord Injury",
  "Amputation",
  "Other",
].map((x) => ({ label: x, value: x }));

const onFieldActions = ["No action", "Penalty", "Sin Bin", "Send-Off", "Placed on Report"];

// Additional question types
const additionalQTypes = [
  { name: "Text Input", type: 1 },
  { name: "Dropdown", type: 2 },
  { name: "Numeric Input", type: 3 },
  { name: "Checkbox", type: 4 },
];

const fixtureMinutes = {
  60: ["00"],
  30: ["00", "30"],
  15: ["00", "15", "30", "45"],
  5: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
};
const injuryStatus = ["Completed", "Pending"];

const idTypes = [
  {
    type: "birth-certificate",
    name: "Birth Certificate",
  },
  {
    type: "drivers-licence",
    name: "Driver's Licence",
  },
  {
    type: "passport",
    name: "Passport",
  },
  {
    type: "photo-id",
    name: "Photo ID",
  },
  {
    type: "other",
    name: "Other",
  },
];

const intervalPosArr = [
  { type: "Fullback", name: "Fullback" },
  { type: "Wing-1", name: "Winger" },
  { type: "Centre-1", name: "Centre" },
  { type: "Centre-2", name: "Centre" },
  { type: "Wing-2", name: "Winger" },
  { type: "Five-Eighth", name: "Five-Eighth" },
  { type: "Halfback", name: "Halfback" },
  { type: "Prop-1", name: "Prop" },
  { type: "Hooker", name: "Hooker" },
  { type: "Prop-2", name: "Prop" },
  { type: "SecondRow-1", name: "Second Row" },
  { type: "SecondRow-2", name: "Second Row" },
  { type: "Lock", name: "Lock" },
];

module.exports = {
  orgtree,
  errormsg,
  ageLevels,
  duplicateAgeLevels,
  finalTemplates,
  roles,
  memberTypes,
  nonPlayerRoles,
  nonPlayerRolesTFA,
  uploadURL,
  supportDocumentURL,
  headshotUploadURL,
  productUploadURL,
  genders,
  matchStatus,
  matchStatusBulk,
  nrlScoring,
  tfaScoring,
  interchanges,
  teamListStatus,
  entityType,
  nationals,
  sortBy,
  dispensationTypes,
  dispensationRules,
  allSeasons,
  historicSeasons,
  historicStatsSeasons,
  historicSomeSeasons,
  currentSeason,
  seasonTags,
  states,
  wwcStatus,
  autoApprovals,
  registrationStatus,
  matchReports,
  gamePeriods,
  compFeeTypes,
  caseCharges,
  compCasesTypes,
  chargeOutcomes,
  tribunalStatus,
  penaltyTypes,
  compCaseScenes,
  grassrootGameStates,
  grassrootGameStatesTypes,
  disabilityTypes,
  onFieldActions,
  mysidelineSeasons,
  additionalQTypes,
  programStatuses,
  clearancePermitStatuses,
  injuryStatus,
  natureOfInjuryOptions,
  howPlayerInjuredList,
  playerSelfStateList,
  methodRemovedFromField,
  referredTo,
  immediateCare,
  injuryAvailability,
  fixtureMinutes,
  idTypes,
  yesNoOptions,
  yesOrNo,
  firstResponderRoleList,
  eliteGameStates,
  intervalPosArr,
};
