<template>
  <el-form label-position="top" :model="program" :rules="rules" ref="program">
    <el-row
      :gutter="10"
      class="tw-py-4"
      v-if="
        (this.$store.getters['user/activeRole'].type === 98 ||
          this.$store.getters['user/activeRole'].type === 99 ||
          this.$store.getters['user/activeRole'].type === 1) &&
        allregionprogramadmins.length > 0
      "
    >
      <el-col :span="12">
        <el-form-item prop="owner" label="Program Lead">
          <el-select
            v-model="program.owner"
            placeholder="Select user for this program"
            @change="updateProgramCollaboratorsList"
            filterable
            :disabled="this.$store.getters['user/activeRole'].type === 99"
          >
            <el-option
              v-for="item in allregionprogramadmins"
              :label="`${item.profile.firstName} ${item.profile.lastName}`"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Program Collaborators">
          <el-select
            v-model="program.collaborators"
            placeholder="Select user for this program"
            multiple
            filterable
            :disabled="this.$store.getters['user/activeRole'].type === 99"
          >
            <el-option
              v-for="item in allregionprogramadminsExcludingOwner"
              :label="`${item.profile.firstName} ${item.profile.lastName}`"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-tabs type="card">
      <el-tab-pane label="Program Details">
        <!-- <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        > -->
        <div class="tw-flex tw-justify-center tw-w-full">
          <img
            v-if="currentLogo"
            :src="currentLogo"
            @error="displayPlaceholder"
            alt=""
            class="tw-w-24"
          />
        </div>

        <div class="tw-flex tw-justify-center tw-w-full tw-px-1">
          <vue-upload-component
            v-if="this.$store.getters['user/activeRole'].type === 98"
            ref="uploadProgramLogo"
            :drop="true"
            v-model="files"
            :extensions="extensions"
            :accept="accept"
            :post-action="uploadURL"
            :headers="uploadHeaders"
            :data="entityData"
            :multiple="true"
            @input-file="inputFile"
            @input-filter="inputFilter"
            class="el-button el-button--default upload"
          >
            Upload
          </vue-upload-component>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form class="tw-flex-1 tw-w-full">
            <el-form-item label="Program Registration Link">
              <el-input
                type="text"
                autocomplete="off"
                v-model="programPrivateFinderLink"
                readonly
                disabled=""
              >
                <template slot="append">
                  <el-button
                    v-clipboard="programPrivateFinderLink"
                    @click="$customSuccess('Successfully copied to clipboard')"
                  >
                    Copy
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <el-form-item class="tw-flex-2" label="Entity Name"
            ><el-select
              v-model="name"
              placeholder="Select a game dev region"
              filterable
              @change="addGamedevregion"
              :disabled="disabled"
            >
              <el-option
                v-for="row in entityList"
                :label="row.name"
                :key="row._id"
                :value="row._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100 sm:tw-flex-row"
        >
          <el-form-item class="tw-flex-1" prop="programtype" label="Program Type">
            <el-select
              v-model="programtype"
              placeholder="Select program type"
              filterable
              :disabled="programTypeDisabled"
            >
              <el-option
                v-for="item in allprogramsettings.map((ps) => ({ type: ps.type }))"
                :label="item.type"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="venue" label="Venue">
            <el-select
              v-model="venue"
              :remote="true"
              :remote-method="filter"
              placeholder="Enter venue to start searching"
              filterable
            >
              <el-option
                v-for="item in allvenues"
                :label="item.concatName"
                :key="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="programname" label="Program Name">
            <el-input autocomplete="off" v-model="program.name" placeholder="Enter program name" />
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        >
          <div class="tw-flex-1">
            <el-form-item prop="numberOfSessions" label="No. Of Sessions">
              <el-select v-model="numberOfSessions" placeholder="Select no. of sessions" filterable>
                <el-option-group v-for="group in availableSessionNos" :key="group.duration">
                  <el-option
                    v-for="item in group.combinations"
                    :key="item._id"
                    :label="
                      group.duration +
                      ` - Price $${item.price / 100}, Min age: ${item.minAge}, Max age: ${
                        item.maxAge
                      }`
                    "
                    :value="JSON.stringify(item)"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="minage" label="Minimum Age">
              <el-input-number
                v-model="minage"
                :min="selectedProgramSettings.minAge"
                :max="selectedProgramSettings.maxAge"
                placeholder="Enter min. age"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="maxage" label="Maximum Age">
              <el-input-number
                v-model="maxage"
                :min="selectedProgramSettings.minAge"
                :max="selectedProgramSettings.maxAge"
                placeholder="Enter max. age"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="activeKidsVoucher" label="Active Kids Voucher">
              <el-select
                v-model="selectedProgramSettings.activeKidsVoucher"
                placeholder="Active Kids Voucher"
                disabled="disabled"
              >
                <el-option
                  v-for="item in [
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="price" label="Price">
              <el-input
                type="text"
                autocomplete="off"
                v-model="price"
                placeholder="$"
                @change="alertChange"
                :disabled="setDisable"
              />
            </el-form-item>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form-item class="tw-flex-1" prop="gender" label="Gender">
            <el-select v-model="program.gender" placeholder="Select gender" filterable>
              <el-option v-for="item in allgenders" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="capacity" label="Capacity">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.capacity"
              placeholder="Enter capacity"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="hours" label="Duration (hours)">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.hours"
              placeholder="Enter no. hours"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="minutes" label="Duration (mins)">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.minutes"
              placeholder="Enter no. minutes"
            />
          </el-form-item>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form-item class="tw-w-48" prop="startTime" label="Start Time">
            <el-time-picker
              v-model="program.startTime"
              format="HH:mm"
              value-format="HH:mm"
              :picker-options="{
                selectableRange: '05:00:00 - 23:59:59',
              }"
              placeholder="Select start time"
            >
            </el-time-picker>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="startdate" label="Start-End Date">
            <el-date-picker
              value-format="timestamp"
              v-model="daterange"
              type="daterange"
              format="yyyy/MM/dd"
              start-placeholder="Start date"
              end-placeholder="End date"
            />
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        >
          <el-form-item
            class="tw-flex-1"
            prop="registrationCloseDate"
            label="Registration close date"
          >
            <el-date-picker
              format="dd/MM/yyyy"
              value-format="timestamp"
              v-model="program.registrationCloseDate"
              type="date"
              placeholder="Select an end date"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="activeInFinder" label="Active In Finder">
            <el-select v-model="program.activeInFinder" placeholder="Select an option" filterable>
              <el-option
                v-for="item in [
                  { option: 'Yes', value: true },
                  { option: 'No', value: false },
                ]"
                :label="item.option"
                :key="item.option"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="tw-flex-1"
            prop="isPrivateActive"
            label="Accept Private Registrations?"
          >
            <el-select v-model="program.isPrivateActive" placeholder="Select an option" filterable>
              <el-option
                v-for="item in [
                  { option: 'Yes', value: true },
                  { option: 'No', value: false },
                ]"
                :label="item.option"
                :key="item.option"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div>
            <el-form-item prop="activeInFinder" label="Program Status">
              <el-select
                v-model="programStatus"
                placeholder="Select an option"
                filterable
                @change="statusChange()"
              >
                <el-option v-for="item in programStatuses" :label="item" :key="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="program.status === 'Cancelled - Other'">
            <el-form-item class="tw-flex-1" prop="programcancellationreason" label="Reason">
              <el-input
                autocomplete="off"
                v-model="program.cancellationReason"
                placeholder="Add reason"
              />
            </el-form-item>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="8">
            <el-form-item prop="gdo.name" label="Program Contact Name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.name"
                placeholder="Enter name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="gdo.number" label="Program Contact Number">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.number"
                placeholder="Enter number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="gdo.email" label="Program Email Address">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.email"
                placeholder="Enter email"
              />
            </el-form-item>
          </el-col>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-form-item prop="meta.website" label="Website">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.meta.website"
                placeholder="Enter a website"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="24">
            <el-form-item prop="notes" label="Finder Description">
              <el-input
                autocomplete="off"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 20 }"
                v-model="program.notes"
                placeholder="Add program details"
              />
            </el-form-item>
          </el-col>
        </div>
        <action-buttons submit cancel @submit="submitForm('program')" @cancel="cancelForm()" />
      </el-tab-pane>
      <el-tab-pane label="Members" v-if="this.type === 'update'">
        <!-- <el-row class="search-bar">
        <el-col :span="6">
          <el-input
            autocomplete="off"
            v-model="filters[0].value"
            placeholder="Search...">
          </el-input>
        </el-col>
      </el-row> -->
        <data-tables
          :data="tableData"
          :table-props="tableProps"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
          <el-table-column fixed prop="profile.firstName" label="First Name" width="130">
          </el-table-column>
          <el-table-column fixed prop="profile.lastName" label="Last Name" width="130">
          </el-table-column>
          <el-table-column fixed prop="profile.gender" label="Gender" width="100">
          </el-table-column>
          <el-table-column
            fixed
            :formatter="dobFormatter"
            prop="profile.dob"
            label="Age"
            width="120"
          >
          </el-table-column>
          <el-table-column fixed prop="profile.email" label="Email" width="auto"> </el-table-column>
        </data-tables>
      </el-tab-pane>
      <el-tab-pane
        label="Saleable Items"
        v-if="
          this.type === 'update' &&
          (clearance === roles.superAdmin ||
            clearance === roles.gamedevregionAdmin ||
            clearance === roles.programAdmin)
        "
      >
        <saleable-items :program="program" :programSetting="programSetting" />
      </el-tab-pane>
      <el-tab-pane
        label="Additional Questions"
        v-if="
          this.type === 'update' &&
          (clearance === roles.superAdmin ||
            clearance === roles.gamedevregionAdmin ||
            clearance === roles.programAdmin)
        "
      >
        <AdditionalQuestions :program="program" :programSetting="programSetting" />
      </el-tab-pane>
      <el-tab-pane
        label="Discount Codes"
        v-if="
          clearance === roles.superAdmin ||
          clearance === roles.gamedevregionAdmin ||
          clearance === roles.programAdmin
        "
      >
        <DiscountsCode
          :program="program"
          :programSetting="programSetting"
          :discountCodes="discountCodes"
          :programSettingDiscountCodes="programSettingDiscountCodes"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Assets"
        v-if="
          clearance === roles.superAdmin ||
          clearance === roles.gamedevregionAdmin ||
          clearance === roles.programAdmin
        "
      >
        <Assets :program="program" :programSetting="programSetting" />
      </el-tab-pane>
      <el-tab-pane label="Communications" v-if="clearance === roles.superAdmin">
        <Communications :program="program" :programSetting="programSetting" />
      </el-tab-pane>
      <el-tab-pane
        label="Logs"
        v-if="
          this.type === 'update' &&
          (clearance === roles.superAdmin ||
            clearance === roles.gamedevregionAdmin ||
            clearance === roles.programAdmin)
        "
      >
        <logs :program="program" />
      </el-tab-pane>
      <el-tab-pane
        label="Refund"
        v-if="
          this.type === 'update' &&
          this.refund &&
          (clearance === roles.superAdmin ||
            clearance === roles.gamedevregionAdmin ||
            clearance === roles.programAdmin)
        "
      >
        <Refund :program="program" :memberRes="this.tableData" />
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import moment from "moment";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, genders, programStatuses, roles } from "../../utils/constants";
import { getS3URL, calculateAge } from "../../utils/utilities";
import SaleableItems from "./ProgramsSaleableItems.vue";
import AdditionalQuestions from "./ProgramsAdditionalQuestions.vue";
import DiscountsCode from "./ProgramsDiscountsCode.vue";
import Assets from "./ProgramAssets.vue";
import Communications from "./ProgramsComms.vue";
import Logs from "./Logs.vue";
import Refund from "./Refund.vue";
import { uploadURL } from "../../utils/constants";

const dobToAge = (member) => {
  if (member) {
    const age = calculateAge(member.profile.dob, true);
    return age;
  }
  return "N/A";
};

export default {
  name: "ProgramsForm",
  components: {
    ActionButtons,
    SaleableItems,
    AdditionalQuestions,
    DiscountsCode,
    Assets,
    Communications,
    Logs,
    Refund,
  },
  props: {},
  data() {
    const validateProgramType = (_, value, callback) => {
      if (!this.program.settings.type) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateProgramStatusCancellationReason = (_, value, callback) => {
      if (!this.program.cancellationReason && this.program.status === "Cancelled - Other") {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateDuration = (_, value, callback) => {
      if (!this.program.settings.duration) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateProgramName = (_, value, callback) => {
      if (!this.program.name) {
        return callback(new Error(""));
      }
      return callback();
    };
    const startDateValidator = (_, value, callback) => {
      if (!parseInt(this.program.startDate) || parseInt(this.program.startDate) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const endDateValidator = (_, value, callback) => {
      if (!parseInt(this.program.endDate) || parseInt(this.program.endDate) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateMinAge = (_, value, callback) => {
      if (
        !this.program.settings.minAge ||
        !parseInt(this.program.settings.minAge) ||
        parseInt(this.program.settings.minAge) <= 0 ||
        parseInt(this.program.settings.minAge) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateMaxAge = (_, value, callback) => {
      if (
        !this.program.settings.maxAge ||
        !parseInt(this.program.settings.maxAge) ||
        parseInt(this.program.settings.maxAge) <= 0 ||
        parseInt(this.program.settings.maxAge) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validatePrice = (_, value, callback) => {
      if (this.program.settings.price === -1) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateCapcity = (rule, value, callback) => {
      if (
        !this.program.capacity ||
        !parseInt(this.program.capacity) ||
        parseInt(this.program.capacity) <= 0 ||
        parseInt(this.program.capacity) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const activeKidsValidator = (rule, value, callback) => {
      if (!this.program.settings) {
        return callback(new Error(""));
      }
      return callback();
    };
    return {
      programStatus: "",
      refund: false,
      entityList: [],
      gamedevregionList: [],
      clubList: [],
      programCollaborators: [],
      name: "",
      disabled: false,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
      files: [],
      programTypeDisabled: false,
      price: 0,
      roles,
      type: "insert",
      disableVoucher: true,
      tableData: [],
      tableProps: {
        border: true,
      },
      programPrivateFinderLink: "",
      clubData: {},
      program: {
        _id: null,
        name: null,
        orgtree: {
          national: {
            _id: null,
            name: null,
          },
          gamedevregion: {
            _id: null,
            name: null,
          },
          club: {
            _id: null,
            name: null,
          },
        },
        settings: {
          _id: null,
          type: null,
          duration: null,
          minAge: -1,
          maxAge: -1,
          price: -1,
          activeKidsVoucher: false,
        },
        emailTemplateId: null,
        owner: null,
        collaborators: [],
        gender: null,
        hours: null,
        minutes: null,
        capacity: null,
        startTime: undefined,
        startDate: undefined,
        endDate: undefined,
        registrationCloseDate: null,
        activeInFinder: false,
        isPrivateActive: null,
        notes: null,
        venue: { name: null, _id: null },
        gdo: {
          name: null,
          number: null,
          email: null,
        },
        saleableItems: [],
        additionalQuestions: [],
        meta: {
          website: "",
          avatar: "",
        },
      },
      foundProgramLead: {},
      programSetting: {},
      programStatuses,
      cancellationReason: "",
      allgenders: genders,
      allvenues: [],
      allprogramsettings: [],
      discountCodes: [],
      programSettingDiscountCodes: [],
      currentprogramsettings: [],
      selectedProgramSettings: {
        duration: null,
        minAge: -1,
        maxAge: -1,
        price: -1,
        activeKidsVoucher: false,
      },
      allregionprogramadmins: [],
      allregionprogramadminsExcludingOwner: [],
      rules: {
        owner: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        programtype: {
          required: true,
          validator: validateProgramType,
          message: errormsg.select_option,
          trigger: "blur",
        },
        programcancellationreason: {
          required: true,
          validator: validateProgramStatusCancellationReason,
          message: errormsg.input_option,
          trigger: "blur",
        },
        programname: {
          required: true,
          validator: validateProgramName,
          message: errormsg.input_option,
          trigger: "blur",
        },
        numberOfSessions: {
          required: true,
          validator: validateDuration,
          message: errormsg.select_option,
          trigger: "blur",
        },
        minage: {
          type: "number",
          required: true,
          validator: validateMinAge,
          message: errormsg.input_option,
          trigger: "blur",
        },
        maxage: {
          type: "number",
          required: true,
          validator: validateMaxAge,
          message: errormsg.input_option,
          trigger: "blur",
        },
        price: {
          type: "number",
          required: true,
          validator: validatePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        gender: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        capacity: {
          required: true,
          validator: validateCapcity,
          message: errormsg.input_option,
          trigger: "blur",
        },
        hours: {
          type: "number",
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        minutes: {
          type: "number",
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        startTime: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        startdate: {
          required: true,
          validator: startDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        enddate: {
          required: true,
          validator: endDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        registrationCloseDate: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        venue: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        activeinfinder: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        activeKidsVoucher: {
          required: true,
          validator: activeKidsValidator,
          message: errormsg.select_option,
          trigger: "blur",
        },
        "gdo.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "gdo.number": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "gdo.email": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.website": {
          validator: (rule, val, callback) => {
            const expression =
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            const regex = new RegExp(expression);

            if (val.length && !val.match(regex)) {
              return callback(new Error(errormsg.invalidUrl));
            }
            return callback();
          },
          trigger: "blur",
        },
      },
    };
  },
  async mounted() {
    this.$store.commit("root/loading", true);
    const { type, id, settings } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");

    if (id && id !== "" && id !== "add") {
      const allPromises = [
        this.$http.get(`/nrl/api/v1/admin/members/program-members/${id}`),
        this.$http.get(`/nrl/api/v1/admin/programs/${id}`),
        this.$http.get("/nrl/api/v1/admin/programs-settings"),
      ];
      if (this.$store.getters["user/activeRole"].type === 98) {
        allPromises.push(
          this.$http.get(
            `/nrl/api/v1/admin/gamedevregion/${this.$store.getters["user/activeRole"].entity._id}`
          )
        );
      }

      // const memberRes = await this.$http.get(`/nrl/api/v1/admin/members/program-members/${id}`);
      // const programRes = await this.$http.get(`/nrl/api/v1/admin/programs/${id}`);
      // const programsettingRes = await this.$http.get("/nrl/api/v1/admin/programs-settings");

      // let programadminuserRes;
      // if (this.$store.getters["user/activeRole"].type === 98) {
      //   programadminuserRes = await this.$http.get(
      //     `/nrl/api/v1/admin/gamedevregion/${this.$store.getters["user/activeRole"].entity._id}`
      //   );
      // }

      Promise.all(allPromises)
        .then(([memberRes, programRes, programsettingRes, programadminuserRes]) => {
          // try {

          this.program = Object.assign({}, this.program, programRes.data.data);
          this.programStatus = this.program.status;

          // To show Refund Tab on cancelled program
          if (this.program.status && this.program.status.includes("Cancelled")) this.refund = true;
          if (
            this.program &&
            this.program._id &&
            this.program.settings &&
            this.program.settings._id
          ) {
            if (programsettingRes && programsettingRes.data && programsettingRes.data.data) {
              this.programSetting = programsettingRes.data.data.find(
                (p) => p._id === this.program.settings._id
              );
            } else this.programSetting = [];
          }

          this.programPrivateFinderLink = this.privateFinderLink();

          // Add Entity Name
          if (
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.gamedevregion &&
            this.program.orgtree.gamedevregion.name
          ) {
            this.name = this.program.orgtree.gamedevregion.name;
            // this.disabled = true;
          } else if (
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.club &&
            this.program.orgtree.club.name
          ) {
            this.name = this.program.orgtree.club.name;
            // this.disabled = true;
          }

          if (this.$store.getters["user/activeRole"].type !== 1) {
            this.disabled = true;
          }

          if (
            (this.$store.getters["user/activeRole"].type === 1 ||
              this.$store.getters["user/activeRole"].type === 99 ||
              this.$store.getters["user/activeRole"].type === 98) &&
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.gamedevregion &&
            this.program.orgtree.gamedevregion._id
          ) {
            Promise.all([
              this.$http.get(
                `/nrl/api/v1/admin/gamedevregion/${this.program.orgtree.gamedevregion._id}`
              ),
            ]).then(([superProgramsadminuserRes]) => {
              programadminuserRes = superProgramsadminuserRes;
              if (
                programadminuserRes &&
                programadminuserRes.data &&
                programadminuserRes.data.data
              ) {
                this.allregionprogramadmins = programadminuserRes.data.data;
                this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
                  (progAdmin) => progAdmin._id !== this.program.owner
                );
              }
            });
            if (programadminuserRes && programadminuserRes.data && programadminuserRes.data.data) {
              this.allregionprogramadmins = programadminuserRes.data.data;
            }

            if (this.allregionprogramadmins) {
              this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
                (progAdmin) => progAdmin._id !== this.program.owner
              );
            }
          }

          if (
            (this.$store.getters["user/activeRole"].type === 1 ||
              this.$store.getters["user/activeRole"].type === 5) &&
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.club &&
            this.program.orgtree.club._id
          ) {
            this.name = this.program.orgtree.club.name;

            Promise.all([
              this.$http.get(`/nrl/api/v1/admin/clubsData/${this.program.orgtree.club._id}`),
            ]).then(([clubRes]) => {
              if (clubRes && clubRes.data && clubRes.data.data) {
                this.clubData = clubRes.data.data;

                if (
                  this.program &&
                  this.program.settings &&
                  this.program.settings._id &&
                  // this.programSetting.visibleToClubs.find((c) => c === this.clubData._id)
                  this.clubData.orgtreeV2Read.programsSettings.includes(
                    this.program.settings._id ||
                      this.clubData.orgtreeV2Write.programsSettings.includes(
                        this.program.settings._id
                      )
                  )
                ) {
                  Promise.all([
                    this.$http.get(`/nrl/api/v1/admin/user/${this.program.owner}`),
                  ]).then(([userData]) => {
                    if (userData && userData.data && userData.data.data) {
                      const user = [];
                      user.push(userData.data.data);
                      this.allregionprogramadmins = user;
                    }
                  });
                }
              }
            });
          }

          if (this.program && this.program.saleableItems && this.program.saleableItems.length > 0) {
            const items_pricefloat = this.program.saleableItems.map((o) => {
              o.price_int = parseInt(o.price);
              o.price = (parseFloat(o.price) / 100).toFixed(2);
              if (!o.gender) o.gender = "";
              return o;
            });
            this.program.saleableItems = items_pricefloat;
          }

          this.allprogramsettings = programsettingRes.data.data.map((p) => ({
            _id: p._id,
            type: p.type,
            options: p.options
              .map((o) => o.duration)
              .filter((d, index, self) => self.indexOf(d) === index)
              .map((o) => ({
                duration: o,
                combinations: p.options
                  .filter((option) => option.duration === o)
                  .map((option) => ({
                    minAge: option.minAge,
                    maxAge: option.maxAge,
                    price: option.price,
                    activeKidsVoucher: option.activeKidsVoucher,
                    duration: o,
                    _id: option._id,
                  })),
              })),
          }));

          this.setCurrentProgramSetting();
          this.tableData = memberRes.data.data;
          this.$store.commit("root/loading", false);
          // } catch (e) {
          //   this.$store.commit("root/loading", false);
          //   this.$customError(e);
          // }
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
      this.fetchData();
      if (
        this.$store.getters["user/activeRole"].type === 2 ||
        this.$store.getters["user/activeRole"].type === 3 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 99 ||
        this.$store.getters["user/activeRole"].type === 105
      ) {
        this.programTypeDisabled = true;
      }
    } else {
      const allPromises = [this.$http.get("/nrl/api/v1/admin/programs-settings")];

      // Set initial GDO values if no program present
      this.program.gdo.name = this.$store.getters["user/userName"];
      this.program.gdo.email = this.$store.getters["user/email"];

      if (this.$store.getters["user/activeRole"].type === 98) {
        allPromises.push(
          this.$http.get(
            `/nrl/api/v1/admin/gamedevregion/${this.$store.getters["user/activeRole"].entity._id}`
          )
        );
      } else if (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 105
      ) {
        allPromises.push(
          this.$http.get(
            `/nrl/api/v1/admin/programs-type-settings/request/${this.$store.getters["user/activeRole"].entity._id}`
          )
        );
      }
      Promise.all(allPromises)
        .then(([programsettingRes, programadminuserRes]) => {
          if (programadminuserRes && programadminuserRes.data && programadminuserRes.data.data) {
            this.allregionprogramadmins = programadminuserRes.data.data;

            this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
              (progAdmin) => progAdmin._id !== this.program.owner
            );
          }
          if (programadminuserRes) {
            this.allregionprogramadmins = programadminuserRes.data.data;

            if (this.program.owner) {
              this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins(
                (progAdmin) => progAdmin._id !== this.program.owner
              );
            }
          }

          let programsettingResult = programsettingRes.data.data; // Visibility to the club at program Type settings

          // Filter the existing program type from the drop down list for Types for
          // clubs when managing the program so that they can add program of one Type only
          if (
            this.$store.getters["user/activeRole"].type === 5 ||
            this.$store.getters["user/activeRole"].type === 105
          ) {
            // Approved Types on Program Type settings Request
            const programTypes = programadminuserRes.data.data.map((p) => p.programTypeId);

            programsettingResult = programsettingRes.data.data.filter((ps) =>
              programTypes.includes(ps._id)
            );
            this.disabled = true;
            this.name = this.$store.getters["user/activeRole"].entity.name;
          }

          this.allprogramsettings = programsettingResult.map((p) => ({
            _id: p._id,
            type: p.type,
            options: p.options
              .map((o) => o.duration)
              .filter((d, index, self) => self.indexOf(d) === index)
              .map((o) => ({
                duration: o,
                combinations: p.options
                  .filter((option) => option.duration === o)
                  .map((option) => ({
                    minAge: option.minAge,
                    maxAge: option.maxAge,
                    price: option.price,
                    activeKidsVoucher: option.activeKidsVoucher,
                    duration: o,
                  })),
              })),
          }));

          if (
            this.$store.getters["user/activeRole"].type === 98 ||
            this.$store.getters["user/activeRole"].type === 99 ||
            this.$store.getters["user/activeRole"].type === 105
          ) {
            this.disabled = true;
            this.name = this.$store.getters["user/activeRole"].entity.name;
          }
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }

    this.price =
      this.program.settings.price && parseInt(this.program.settings.price) > 0
        ? `$  ${(parseInt(this.program.settings.price) / 100).toFixed(2)}`
        : "$ 0";

    this.$store.commit("root/loading", true);
    if (
      this.$store.getters["user/activeRole"].type === 98 ||
      this.$store.getters["user/activeRole"].type === 99
    ) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/game-dev-regions")
        .then((response) => {
          this.entityList = response.data.data;
          this.gamedevregionList = this.entityList;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    } else if (this.$store.getters["user/activeRole"].type === 5) {
      this.$http
        .get("/nrl/api/v1/admin/clubs")
        .then((response) => {
          this.entityList = response.data.data;
          this.clubList = this.entityList;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    } else if (this.$store.getters["user/activeRole"].type === 1) {
      this.$http
        .get("/nrl/api/v1/admin/game-dev-regions")
        .then((response) => {
          this.entityList = response.data.data;
          this.gamedevregionList = response.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
      this.$http
        .get("/nrl/api/v1/admin/clubs")
        .then((response) => {
          this.entityList = [...this.entityList, ...response.data.data];
          this.clubList = response.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    }
  },
  methods: {
    statusChange() {
      if (this.program && this.program.refundStatus && this.programStatus !== this.program.status) {
        this.$alert("You cannot change a refunded program status");
        return false;
      }
    },
    async fetchData() {
      try {
        this.$store.commit("root/loading", true);
        const response = await this.$http.get(
          `/nrl/api/v1/admin/registration-products/discount-codes/programs/${this.$route.params.id}`
        );

        const codes = response.data.data;
        this.discountCodes = codes || [];
        const prog = await this.$http.get(`/nrl/api/v1/admin/programs/${this.$route.params.id}`);
        const programData = prog.data.data;
        const res = await this.$http.get(
          `/nrl/api/v1/admin/registration-products/discount-codes/programs-settings/${programData.settings._id}`,
          { body: programData.settings._id }
        );

        const settingCodes = res.data.data;
        this.programSettingDiscountCodes = settingCodes || [];
        this.$store.commit("root/loading", false);
      } catch (e) {
        this.$customError(e);
        this.$store.commit("root/loading", false);
      }
    },
    updateProgramCollaboratorsList() {
      this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
        (progAdmin) => progAdmin._id !== this.program.owner
      );
    },
    addGamedevregion(value) {
      const entity = this.entityList.find((g) => g._id === value);
      if (entity) {
        if (this.gamedevregionList.find((g) => g === entity)) {
          this.program.orgtree.gamedevregion = {};
          this.program.orgtree.gamedevregion._id = entity._id;
          this.program.orgtree.gamedevregion.name = entity.name;

          if (
            (this.$store.getters["user/activeRole"].type === 1 ||
              this.$store.getters["user/activeRole"].type === 99) &&
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.gamedevregion &&
            this.program.orgtree.gamedevregion._id
          ) {
            Promise.all([
              this.$http.get(
                `/nrl/api/v1/admin/gamedevregion/${this.program.orgtree.gamedevregion._id}`
              ),
            ]).then(([superProgramsadminuserRes]) => {
              if (
                superProgramsadminuserRes &&
                superProgramsadminuserRes.data &&
                superProgramsadminuserRes.data.data
              ) {
                this.allregionprogramadmins = superProgramsadminuserRes.data.data;

                this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
                  (progAdmin) => progAdmin._id !== this.program.owner
                );

                // clear collaborators list
                this.program.collaborators = [];
              }
            });
          }
        } else if (this.clubList.find((g) => g === entity)) {
          this.program.orgtree.club = {};
          this.program.orgtree.club._id = entity._id;
          this.program.orgtree.club.name = entity.name;

          if (
            (this.$store.getters["user/activeRole"].type === 1 ||
              this.$store.getters["user/activeRole"].type === 5) &&
            this.program &&
            this.program.orgtree &&
            this.program.orgtree.club &&
            this.program.orgtree.club._id
          ) {
            Promise.all([
              this.$http.get(`nrl/api/v1/admin/club/${this.program.orgtree.club._id}`),
            ]).then(([superProgramsadminuserRes]) => {
              if (
                superProgramsadminuserRes &&
                superProgramsadminuserRes.data &&
                superProgramsadminuserRes.data.data
              ) {
                this.allregionprogramadmins = superProgramsadminuserRes.data.data;

                this.allregionprogramadminsExcludingOwner = this.allregionprogramadmins.filter(
                  (progAdmin) => progAdmin._id !== this.program.owner
                );

                // clear collaborators list
                this.program.collaborators = [];
              }
            });
          }
        }
      }
    },
    async alertChange(value) {
      const oldPrice = this.program.settings.price;

      if (value.includes("$")) {
        this.price = value;
      } else {
        this.price = `$  ${parseFloat(value).toFixed(2)}`;
      }
      try {
        const confirm = await this.$confirm(
          "This price does not comply with the standards for this program.I understand.",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
      } catch (e) {
        if (e !== "cancel") return;
        this.price = `$  ${(oldPrice / 100).toFixed(2)}`;
        return;
      }

      if (value.includes("$")) {
        const priceValue = value.replace("$", " ");
        this.program.settings.price = parseFloat(priceValue) * 100;
      } else {
        this.program.settings.price = parseFloat(value) * 100;
      }
    },
    setCurrentProgramSetting() {
      if (this.allprogramsettings && this.allprogramsettings.length > 0) {
        this.allprogramsettings.filter((p, i) => {
          if (p._id === this.program.settings._id) {
            this.currentprogramsettings = p.options;
            const combination = this.currentprogramsettings.find(
              (option) => option.combinations[0].duration === this.program.settings.duration
            );
            if (combination) {
              // this.selectedProgramSettings.duration = this.program.settings.duration;
              // this.selectedProgramSettings.minAge = this.program.settings.minAge;
              // this.selectedProgramSettings.maxAge = this.program.settings.maxAge;
              this.selectedProgramSettings.duration = combination.combinations[0].duration;

              this.selectedProgramSettings.minAge = combination.combinations[0].minAge;

              this.selectedProgramSettings.maxAge = combination.combinations[0].maxAge;
              this.selectedProgramSettings.price = this.program.settings.price;
              this.selectedProgramSettings.activeKidsVoucher =
                combination.combinations[0].activeKidsVoucher;
            }
          }
        });
      }

      this.price =
        this.selectedProgramSettings.price && parseInt(this.selectedProgramSettings.price) > 0
          ? `$  ${(parseInt(this.selectedProgramSettings.price) / 100).toFixed(2)}`
          : "$ 0";
    },
    finderLink() {
      const portalURL =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-profile.mysideline.xyz/"
          : "https://profile.mysideline.com.au/";
      const baseLink = new URL("/register/clubsearch", portalURL);
      baseLink.searchParams.set("criteria", String(this.program._id));
      baseLink.searchParams.set("entity", "true");
      baseLink.searchParams.set("entitytype", "program");
      const source = this.program.orgtree.national._id === 32 ? "rugby-league" : "touch-football";
      baseLink.searchParams.set("source", source);

      return String(baseLink);
    },
    privateFinderLink() {
      // convert to base64
      const data = btoa(
        JSON.stringify({
          entityId: this.program._id,
          entityType: "program",
        })
      );
      const portalURL =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-profile.mysideline.xyz/"
          : "https://profile.mysideline.com.au/";
      const baseLink = new URL("/register/entity", portalURL);
      baseLink.searchParams.set("data", data);
      const source = this.program.orgtree.national._id === 32 ? "rugby-league" : "touch-football";
      baseLink.searchParams.set("source", source);
      return String(baseLink);
    },
    dobFormatter(row) {
      return row && row.profile && row.profile.dob ? dobToAge(row) : "N/A";
    },
    async submitForm(formName) {
      if (this.programStatus && this.programStatus.includes("Cancelled")) {
        this.refund = true;
      }

      if (this.program && this.program.refundStatus && this.program.status.includes("Cancelled")) {
        this.$alert("You cannot change a refunded program status");
        return false;
      }

      this.program.status = this.programStatus;
      //Check if the program Admin/owner  doesnot exists in the list of users having this role
      if (
        this.$store.getters["user/activeRole"].type === 98 ||
        this.$store.getters["user/activeRole"].type === 99 ||
        this.$store.getters["user/activeRole"].type === 1
      ) {
        //Check if the program Admin/owner  doesnot exists in the list of users having this role
        const foundProgramLead = this.allregionprogramadmins.find(
          (progAdmin) => progAdmin._id === this.program.owner
        );

        if (!foundProgramLead && this.$store.getters["user/activeRole"].type !== 99) {
          this.$alert(
            "Please update the Program Lead.  This Lead does not have access to the selected Program Entity"
          );
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.program.orgtree.national._id = this.$store.getters["user/activeRole"].national_id;
          const a = this.$store.getters["user/activeRole"];

          this.program.orgtree.national.name =
            this.$store.getters["user/activeRole"].national_id === 32 ? "NRL" : "TFA";
          if (
            this.$store.getters["user/activeRole"].type === 98 ||
            this.$store.getters["user/activeRole"].type === 99
          ) {
            this.program.orgtree.gamedevregion._id =
              this.$store.getters["user/activeRole"].entity._id;
            this.program.orgtree.gamedevregion.name =
              this.$store.getters["user/activeRole"].entity.name;
          }

          if (
            this.$store.getters["user/activeRole"].type === 5 ||
            this.$store.getters["user/activeRole"].type === 105
          ) {
            this.program.orgtree.club._id = this.$store.getters["user/activeRole"].entity._id;

            this.program.orgtree.club.name = this.$store.getters["user/activeRole"].entity.name;
          }
          this.$store.commit("root/loading", true);

          if (this.type === "update") {
            const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
            const update = this.program;
            this.$http
              .put(url, update)
              .then(() => {
                if (this.program.status && this.program.status.includes("Cancelled")) {
                  this.refund = true;
                }
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            if (
              this.$store.getters["user/activeRole"].type === 99 ||
              this.$store.getters["user/activeRole"].type === 5 ||
              this.$store.getters["user/activeRole"].type === 105
            ) {
              this.program.owner = this.$store.getters["user/userid"];
            } else {
              this.program.owner = this.foundProgramLead._id;
            }
            const insert = this.program;

            const url = "/nrl/api/v1/admin/programs";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "programs",
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }

          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({ name: "programs" });
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allvenues = response.data.data.map((venue) => ({
                ...venue,
                concatName: `${venue.name} - ${venue.address.suburb}`,
              }));
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allvenues = [];
      }
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    handleRowClick(row) {
      this.$router.push({
        name: "members.update",
        params: {
          id: row._id,
        },
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          if (this.program && this.type === null) this.type = "update";
          this.program.meta.avatar = newFile.response.url;

          if (this.type === "update") {
            const { avatar } = this.program.meta;
            const { _id } = this.program;

            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };

            const url = `/nrl/api/v1/admin/programs/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.uploadProgramLogo.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
  },
  computed: {
    entityData() {
      return {
        entity_type: "programs",
        entity_id: this.$route.params.id,
      };
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    uploadURL() {
      return uploadURL;
    },
    setDisable() {
      return this.$store.getters["user/activeRole"].type !== roles.gamedevregionAdmin;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentLogo() {
      if (this.program.meta.avatar) return getS3URL(this.program._id);
      else {
        return this.program && this.program.settings && this.program.settings._id
          ? getS3URL(this.program.settings._id)
          : false;
      }
    },
    availableSessionNos() {
      const { program, allprogramsettings } = this;
      const setting =
        program.settings.type && allprogramsettings.length > 0
          ? allprogramsettings.find((ps) => ps.type === program.settings.type)
          : undefined;

      if (setting && setting.options) {
        return setting.options.map((o, i) => {
          if ("combinations" in o) {
            return o;
          }
        });
      } else {
        return [];
      }
    },
    programtype: {
      get() {
        return this.program.settings.type;
      },
      set(value) {
        const found = this.allprogramsettings.find((p) => p.type === value);
        if (found) {
          this.program.settings = {
            _id: found._id,
            type: value,
            duration: null,
            minAge: -1,
            maxAge: -1,
            price: -1,
            activeKidsVoucher: false,
          };
          this.setCurrentProgramSetting();
        }
      },
    },
    numberOfSessions: {
      get() {
        return this.program.settings.duration;
      },
      set(value) {
        value = JSON.parse(value);
        this.program.settings.duration = value.duration;
        this.program.settings.minAge = value.minAge;
        this.program.settings.maxAge = value.maxAge;
        this.program.settings.price = value.price;
        this.program.settings.activeKidsVoucher = value.activeKidsVoucher;
        this.program.settings.optionId = value._id;
        this.selectedProgramSettings.duration = value.duration;
        this.selectedProgramSettings.minAge = value.minAge;
        this.selectedProgramSettings.maxAge = value.maxAge;
        this.selectedProgramSettings.price = value.price;
        this.selectedProgramSettings.activeKidsVoucher = value.activeKidsVoucher;
        this.selectedProgramSettings.optionId = value._id;
        this.price = `$  ${(parseInt(value.price) / 100).toFixed(2)}`;
      },
    },
    minage: {
      get() {
        return this.program.settings.minAge && parseInt(this.program.settings.minAge) > 0
          ? this.program.settings.minAge
          : null;
      },
      set(value) {
        const minAge = parseInt(value);
        const { type, duration, maxAge } = this.program.settings;
        this.program.settings.minAge = minAge > 0 ? minAge : -1;

        if (duration && minAge > 0 && maxAge > 0 && minAge <= maxAge) {
          const programsetting = this.allprogramsettings.find((setting) => setting.type === type);
          if (programsetting && programsetting.options.length > 0) {
            const combination = programsetting.options.find(
              (option) =>
                option.combinations[0].duration === duration &&
                option.combinations[0].minAge <= minAge &&
                maxAge <= option.combinations[0].maxAge
            );
            // let combination = setting || undefined;
            // if (combination)
            //   combination = combination.filter(c => c.minAge <= minAge && maxAge <= c.maxAge);
          } else {
            this.program.settings.price = -1;
          }
        } else {
          this.program.settings.price = -1;
        }
      },
    },
    maxage: {
      get() {
        return this.program.settings.maxAge && parseInt(this.program.settings.maxAge) > 0
          ? this.program.settings.maxAge
          : null;
      },
      set(value) {
        const maxAge = parseInt(value);

        const { type, duration, minAge } = this.program.settings;
        this.program.settings.maxAge = maxAge > 0 ? maxAge : -1;

        if (duration !== "" && minAge > 0 && maxAge > 0 && minAge <= maxAge) {
          const programsetting = this.allprogramsettings.find((setting) => setting.type === type);
          if (programsetting && programsetting.options.length > 0) {
            const combination = programsetting.options.find((o) => {
              return (
                o.combinations[0].duration === duration &&
                o.combinations[0].minAge <= minAge &&
                maxAge <= o.combinations[0].maxAge
              );
            });

            // .combinations.filter(c => c.minAge <= minAge && maxAge <= c.maxAge);
          } else {
            this.program.settings.price = -1;
          }
        } else {
          this.program.settings.price = -1;
        }
      },
    },
    // price: {
    //   get() {
    //     return this.selectedProgramSettings.price &&
    //       parseInt(this.selectedProgramSettings.price) > 0
    //       ? `$  ${(parseInt(this.selectedProgramSettings.price) / 100).toFixed(2)}`
    //       : "$ 0";
    //   },
    //   set(value) {
    //     this.program.settings.price = value;
    //   },
    // },
    venue: {
      get() {
        return this.program.venue.name || null;
      },
      set(value) {
        const venue = this.allvenues.find((v) => v._id === value);
        this.program.venue = venue;
        if (this.program.settings.type && venue.address && venue.address.suburb) {
          this.program.name = `${this.program.settings.type} - ${venue.address.suburb}`;
        }
      },
    },
    daterange: {
      get() {
        return [this.program.startDate, this.program.endDate];
      },
      set(values) {
        let [startDate, endDate] = values;

        startDate = moment(startDate)
          .set({
            hour: "12",
            minute: "00",
            second: "00",
          })
          .valueOf();

        endDate = moment(endDate)
          .set({
            hour: "12",
            minute: "00",
            second: "00",
          })
          .valueOf();

        this.program.startDate = startDate;
        this.program.endDate = endDate;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.el-select,
.el-input {
  width: 100%;
}
.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}
.avatar {
  margin: 0 auto;
  height: 12.5rem;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.data-table {
  width: 100%;
}
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
