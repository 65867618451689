<template>
  <div class="sidebar" :class="{ uat }">
    <el-row class="image-box">
      <img v-if="logo" :src="logo" @error="displayPlaceholder" class="image" />
      <h3 class="current-entity">{{ activeEntity }}</h3>
      <h4 class="current-role">{{ activeRole }}</h4>
    </el-row>
    <el-row class="menu-box">
      <el-col :span="24">
        <el-menu
          :key="componentKey"
          :collapse="isCollapse"
          :default-active="defaultIndex"
          class="el-menu-sidebar"
          :router="true"
          background-color="#0D0D0D"
          text-color="#fff"
          active-text-color="#848484"
        >
          <el-menu-item index="/">
            <span>Dashboard</span>
          </el-menu-item>
          <div v-if="!isDeveloper">
            <el-menu-item-group
              v-if="
                clearance !== roles.programAdmin &&
                clearance !== roles.gamedevregionAdmin &&
                clearance !== roles.clubProgramAdmin
              "
            >
              <div class="menu-title" :class="{ uat }">Member Management /</div>
              <el-menu-item v-if="clearance <= roles.clubAdmin" index="/member-management">
                <span> My Members</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/duplicate-members">
                <span>Duplicate Members</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  clearance <= roles.clubAdmin &&
                  this.$store.getters['user/activeRole'].national_id === 32
                "
                index="/clearance-permit"
              >
                <span>Clearances / Permits</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  (clearance === roles.clubAdmin || clearance === roles.associationAdmin) &&
                  this.$store.getters['user/activeRole'].national_id === 32
                "
                index="/pending-registrations"
              >
                <span>Pending Registrations</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  (clearance === roles.clubAdmin || clearance === roles.associationAdmin) &&
                  this.$store.getters['user/activeRole'].national_id === 32
                "
                index="/18-month-dispensation"
              >
                <span>18 Month Dispensation</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <div class="menu-title" :class="{ uat }">Administration /</div>
              <el-menu-item
                v-if="
                  clearance !== roles.superAdmin &&
                  clearance !== roles.teamAdmin &&
                  clearance !== roles.programAdmin &&
                  clearance !== roles.clubProgramAdmin
                "
                :index="myEntityPath"
              >
                <span>My {{ myEntity }}</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  clearance !== roles.teamAdmin &&
                  clearance !== roles.programAdmin &&
                  clearance !== roles.clubProgramAdmin
                "
                index="/account-details"
              >
                <span>Bank Account Details</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  clearance !== roles.teamAdmin &&
                  clearance !== roles.gamedevregionAdmin &&
                  clearance !== roles.programAdmin &&
                  clearance !== roles.clubProgramAdmin
                "
                index="/registration-products"
              >
                <span>Products</span>
              </el-menu-item>
              <!-- && this.$store.getters['user/activeRole'].national_id === 32 -->
              <el-menu-item
                v-if="
                  (clearance <= roles.programAdmin || clearance === roles.clubProgramAdmin) &&
                  clearance !== roles.teamAdmin
                "
                index="/reports"
              >
                <span>Reports</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  [
                    roles.clubAdmin,
                    roles.associationAdmin,
                    roles.gamedevregionAdmin,
                    roles.programAdmin,
                    roles.clubProgramAdmin,
                  ].includes(clearance)
                "
                index="/communications"
              >
                <span>Communications</span>
              </el-menu-item>
              <el-menu-item
                v-if="clearance <= roles.clubAdmin || clearance === roles.gamedevregionAdmin"
                index="/user-management"
              >
                <span>User Management</span>
              </el-menu-item>
              <el-menu-item v-if="clearance === roles.superAdmin" index="/program-settings">
                <span>Program Type Settings</span>
              </el-menu-item>
              <el-menu-item
                v-if="clearance === roles.clubAdmin && !isTFA"
                index="/program-settings-request"
              >
                <span>Request Program Type Settings</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  clearance === roles.programAdmin ||
                  clearance === roles.clubProgramAdmin ||
                  clearance === roles.gamedevregionAdmin ||
                  clearance === roles.superAdmin ||
                  (clearance === roles.clubAdmin && !isTFA) ||
                  (clearance === roles.associationAdmin && !isTFA) ||
                  (clearance === roles.regionAdmin && !isTFA) ||
                  (clearance === roles.stateAdmin && !isTFA)
                "
                index="/programs"
              >
                <span>Programs</span>
              </el-menu-item>
            </el-menu-item-group>
            <br />
            <el-menu-item-group
              v-if="
                clearance !== roles.programAdmin &&
                clearance !== roles.gamedevregionAdmin &&
                clearance !== roles.clubProgramAdmin
              "
            >
              <div class="menu-title" :class="{ uat }">Competition Management /</div>
              <el-menu-item v-if="clearance === roles.superAdmin && !isTFA" index="/competition-types">
                <span>Competition Types</span>
              </el-menu-item>
              <el-menu-item v-if="clearance === roles.superAdmin" index="/comp-or-team-list-types">
                <span v-if="isTFA">Competition Types</span>
                <span v-if="!isTFA">Team List Types</span>
              </el-menu-item>
              <el-menu-item v-if="clearance === roles.superAdmin" index="/ladder-templates">
                <span>Ladder Templates</span>
              </el-menu-item>
              <el-menu-item
                v-if="clearance === roles.clubAdmin || clearance === roles.associationAdmin"
                index="/squad-management"
              >
                <span>Squad Management</span>
              </el-menu-item>
              <el-menu-item
                v-if="clearance === roles.associationAdmin"
                index="/restrict-squad-management"
              >
                <span>Restrict Squad Management</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/competitions">
                <span>Competitions</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/matches">
                <span>Matches</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin && isRefereeAssociation" index="/matches/referee-appointments">
                <span>Referee Appointments</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/match-grid">
                <span>Match Grid</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin && !isRefereeAssociation" index="/match-scores">
                <span>Match Scores</span>
              </el-menu-item>

              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/ladders">
                <span>Ladders</span>
              </el-menu-item>
              <el-menu-item
                v-if="clearance <= roles.associationAdmin"
                :index="
                  this.$store.getters['user/activeRole'].national_id === 32
                    ? '/competition-cases'
                    : '/under-construction'
                "
              >
                <span>Competition Cases</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/draws">
                <span>Draw Builder</span>
              </el-menu-item>
              <el-menu-item v-if="clearance === roles.associationAdmin" index="/team-regrading">
                <span>Team Regrading</span>
              </el-menu-item>
              <!-- Hiding as per [SAFE-3543] https://nationalrugbyleague.atlassian.net/browse/SAFE-3543 -->
              <el-menu-item
                v-if="clearance <= roles.associationAdmin && false"
                index="/championship-ladders"
              >
                <span>Championship Ladders</span>
              </el-menu-item>
              <el-menu-item v-if="clearance == roles.superAdmin" index="/expirations">
                <span>Expirations</span>
              </el-menu-item>
            </el-menu-item-group>
            <br />
            <el-menu-item-group
              v-if="
                clearance !== roles.programAdmin &&
                clearance !== roles.gamedevregionAdmin &&
                clearance !== roles.clubProgramAdmin
              "
            >
              <div class="menu-title" :class="{ uat }">Entities /</div>
              <el-menu-item v-if="clearance == roles.superAdmin" index="/states">
                <span>States</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.stateAdmin" index="/regions">
                <span>Regions</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.regionAdmin" index="/associations">
                <span>Associations</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/clubs">
                <span>Clubs</span>
              </el-menu-item>
              <el-menu-item
                v-if="
                  clearance === roles.clubAdmin ||
                  clearance === roles.associationAdmin ||
                  clearance === roles.superAdmin
                "
                index="/teams"
              >
                <span>Teams</span>
              </el-menu-item>
              <el-menu-item v-if="clearance == roles.superAdmin" index="/game-dev-regions">
                <span>Game Dev Regions</span>
              </el-menu-item>
              <el-menu-item v-if="clearance <= roles.associationAdmin" index="/venues">
                <span>Venues</span>
              </el-menu-item>
            </el-menu-item-group>
          </div>
          <el-menu-item-group v-if="this.enableThirdPartyRoute">
            <div class="menu-title" :class="{ uat }">Third party /</div>
            <el-menu-item index="/api-keys-management">
              <span> Api Keys</span>
            </el-menu-item>
          </el-menu-item-group>
          <br /><br /><br />
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { orgtree, roles, nationals } from "../utils/constants";
import { getS3URL } from "../utils/utilities";

export default {
  name: "SideBar",
  props: {},
  data() {
    return {
      componentKey: 0,
      defaultIndex: "/",
      isCollapse: false,
      roles,
      enableThirdPartyRoute: false,
      isRefereeAssociation: false,
    };
  },
  mounted() {
    this.defaultIndex = this.$route.path;
    if (this.$store.getters["user/activeRole"].type === roles.developer) {
      this.enableThirdPartyRoute = true;
    }
    if(this.$store.getters["user/activeRole"].type === roles.associationAdmin) {
      this.$http
      .get("/nrl/api/v1/admin/referee/association")
      .then((response) => {
        if(response && response.data && response.data.association.orgtree.state.type === "referee") {
          this.isRefereeAssociation = true;
        }
      })
      .catch(() => {
        this.$customError();
      });
    }
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === nationals.TFA;
    },
    uat() {
      return process.env.VUE_APP_PIN_ENV === "test";
    },
    logo() {
      const { entity } = this.$store.getters["user/activeRole"];
      return entity ? getS3URL(entity._id) : false;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isDeveloper() {
      if (this.$store.getters["user/activeRole"].type === roles.developer) {
        return true;
      } else {
        return false;
      }
    },
    activeRole() {
      const roleType = this.$store.getters["user/activeRole"].type;
      return roleType !== 99 ? (roleType ? orgtree[roleType].name : "") : "Program Admin";
    },
    activeRoleId() {
      return this.$store.getters["user/activeRole"]
        ? this.$store.getters["user/activeRole"]._id
        : 0;
    },
    activeEntity() {
      const role = this.$store.getters["user/activeRole"];
      return role.entity ? role.entity.name : "";
    },
    myEntity() {
      return this.activeRole
        ? this.activeRole !== "Game Dev Region Admin"
          ? this.activeRole.split(" ")[0]
          : "Game Dev Region"
        : "";
    },
    myEntityPath() {
      const { entity } = this.$store.getters["user/activeRole"];
      if (!entity) {
        return "/";
      }
      const entityId = entity._id;
      switch (this.clearance) {
        case roles.stateAdmin:
          return `/my/states/${entityId}`;
        case roles.regionAdmin:
          return `/my/regions/${entityId}`;
        case roles.associationAdmin:
          return `/my/associations/${entityId}`;
        case roles.clubAdmin:
          return `/my/clubs/${entityId}`;
        case roles.teamAdmin:
          return `/my/teams/${entityId}`;
        case roles.gamedevregionAdmin:
          return `/my/game-dev-regions/${entityId}`;
        default:
          return "/";
      }
    },
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../assets/shield_white.svg");
    },
  },
  watch: {
    activeRoleId(val) {
      this.defaultIndex = "/";
      // force to re-render menu bar
      this.componentKey = val;
    },
    $route(to) {
      this.defaultIndex = to.path;
    },
  },
};
</script>

<style scoped lang="scss">
.uat {
  background-color: #098255 !important;
  .menu-title {
    background-color: #098255 !important;
  }
  .el-menu-sidebar {
    background-color: #098255 !important;
  }
  .el-menu-item {
    background-color: #098255 !important;
  }
  .el-menu-item-group {
    background-color: #098255 !important;
  }
}

.sidebar {
  height: 100%;
  width: 16.66666%;
  position: fixed;
  background-color: $primary;

  h4 {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .image-box {
    text-align: center;
    width: 100%;
  }

  .image {
    padding: 20px;
    width: auto;
    height: 100px;
    margin: 0 auto;
  }

  .el-col {
    height: 100%;
  }

  .el-menu-sidebar {
    height: 100%;
    border-right: 0px;
  }

  .current-role {
    margin-top: 0;
    margin-bottom: 2rem;
    color: white !important;
    font-family: $fontRegular;
  }
  .current-entity {
    margin-top: 0;
    margin-bottom: 0;
    color: white !important;
    font-family: $fontMedium;
  }

  .menu-box {
    height: 80%;
    overflow: scroll;
  }

  .el-menu-item {
    line-height: 40px;
    height: 40px;

    &.is-active {
      background-color: #262626 !important;
      color: white !important;

      span {
        font-family: $fontBold !important;
      }
    }
  }

  .menu-title {
    @apply tw-uppercase tw-text-white tw-bg-black tw-text-sm tw-py-2 tw-px-5 tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden tw-font-bold;
  }

  .menu-title-legacy {
    cursor: default;
    line-height: 40px;
    height: 40px;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(13, 13, 13) !important;
    font-size: 14px !important;
    padding: 20px 20px 0;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: $fontBold;

    &.uat {
      background-color: #098255 !important;
    }
  }
}
</style>
