<template>
  <div class="tw-flex tw-flex-col tw-items-left">
    <el-row :gutter="2" class="mb-2">
      <el-card class="box-card" shadow="never">
        <el-form ref="form" label-width="120px">
          <template>
            <el-checkbox
              v-model="showPreferNameApprovals"
              @change="updateShowPreferNameApprovals($event)"
              >Show preferred names approvals</el-checkbox
            >
            <el-checkbox
              v-model="showRefereeAssociationRequests"
              @change="updateShowRefereeAssociationRequests($event)"
              v-show="isAssociationAdmin && isRefereeAssociation"
              >Show referee appointment requests</el-checkbox
            >
            <el-checkbox
              v-model="showPendingProgramTypeApprovals"
              @change="updateShowPendingProgramTypeApprovals($event)"
              v-show="isSuperAdmin"
              >Show clubs's pending program type settings approvals
            </el-checkbox>

            <el-checkbox
              v-model="showUnavailableMembersReport"
              @change="updateShowUnavailableMembersReport($event)"
              v-show="isValidAdmin"
              >Show unavailable members report</el-checkbox
            >
          </template>
        </el-form>
      </el-card>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24" v-show="showPreferNameApprovals">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Members - preferred name approvals</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleMembers">
              Members Search
            </el-button>
            <el-button
              icon="el-icon-refresh"
              style="float: right; padding: 3px 0"
              type="text"
              @click="handleRefresh('preferred-name')"
            >
              Refresh
            </el-button>
          </div>
          <el-alert :closable="false" type="success">
            By clicking "APPROVE," you are confirming that the displayed name for the participant is
            their preferred name for use on the match center. This name change must comply with the
            games policies.
          </el-alert>
          <data-tables-server
            v-loading="loading"
            :data="members"
            :table-props="tableProps"
            :pagination-props="paginationProps"
            :page-size="memberSearch.pageSize"
            :current-page.sync="memberSearch.page"
            :total="memberSearch.count"
            @query-change="queryChange('preferred-name')"
            class="data-table"
          >
            <el-table-column prop="_id" label="Member Id" width="auto"> </el-table-column>
            <el-table-column prop="profile.firstName" label="First Name" width="auto">
            </el-table-column>
            <el-table-column prop="profile.lastName" label="Last Name" width="auto">
            </el-table-column>
            <el-table-column
              prop="profile.preferFirstName"
              label="Preferred First Name"
              width="auto"
            >
            </el-table-column>
            <el-table-column prop="profile.preferLastName" label="Preferred Last Name" width="auto">
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button key="button" type="text" @click="handleRowClick(scope)">
                  View
                </el-button>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button key="button" type="text" @click="handleApprove(scope)">
                  Approve
                </el-button>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-1" v-show="isAssociationAdmin && isRefereeAssociation">
      <el-col :span="24" v-show="showRefereeAssociationRequests">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Referee Appointment Requests</span>
            <el-button
              icon="el-icon-refresh"
              style="float: right; padding: 3px 0"
              type="text"
              @click="getRefereeRequests"
            >
              Refresh
            </el-button>
          </div>
          <el-alert :closable="false" type="success">
            By clicking "APPROVE," you are confirming that the selected association is able to
            appoint referees in your association.
          </el-alert>
          <data-tables-server
            v-loading="loading"
            :data="associationRequests"
            :table-props="tableProps"
            :pagination-props="paginationProps"
            :page-size="associationRequestsData.pageSize"
            :current-page.sync="associationRequestsData.page"
            :total="associationRequestsData.count"
            class="data-table"
          >
            <el-table-column prop="requestedAssociationId" label="Association Id" width="auto">
            </el-table-column>
            <el-table-column prop="requestedAssociationName" label="Association Name" width="auto">
            </el-table-column>
            <el-table-column prop="requestedCompetitionId" label="Competition Id" width="auto">
            </el-table-column>
            <el-table-column prop="requestedCompetitionName" label="Competition Name" width="auto">
            </el-table-column>

            <el-table-column
              prop="createdAt"
              :formatter="getFormattedDate"
              label="Requested At"
              width="auto"
            >
            </el-table-column>
            <el-table-column label="Status">
              <template slot-scope="scope">
                <el-button
                  v-show="scope.row.status !== 'approved'"
                  key="button"
                  type="text"
                  @click="handleRefereeRequest(scope, 'approved')"
                >
                  Approve
                </el-button>
                <p v-show="scope.row.status === 'approved'">Approved</p>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  v-show="scope.row.status !== 'declined'"
                  key="button"
                  type="text"
                  @click="handleRefereeRequest(scope, 'declined')"
                >
                  Decline
                </el-button>
                <p v-show="scope.row.status === 'declined'">Declined</p>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-1" v-show="isSuperAdmin">
      <el-col :span="24" v-show="showPendingProgramTypeApprovals">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Club requesting for approval to access these Program Type Settings </span>
            <el-button
              icon="el-icon-refresh"
              style="float: right; padding: 3px 0"
              type="text"
              @click="getProgTypeRequests"
            >
              Refresh
            </el-button>
          </div>
          <el-alert :closable="false" type="success">
            By clicking "APPROVE," you are confirming that the club can access this program type
            settings and can create programs associated with this type.
          </el-alert>
          <data-tables-server
            v-loading="loading"
            :data="progTypeSettingsRequests"
            :table-props="tableProps"
            :pagination-props="paginationProps"
            :page-size="progTypeSettingsRequestsData.pageSize"
            :current-page.sync="progTypeSettingsRequestsData.page"
            :total="progTypeSettingsRequestsData.count"
            class="data-table"
          >
            <el-table-column prop="requestedClubId" label="Club Id" width="auto"> </el-table-column>
            <el-table-column prop="requestedClubName" label="Club Name" width="auto">
            </el-table-column>
            <el-table-column prop="type" label="Program Type Setting" width="auto">
            </el-table-column>
            <el-table-column
              prop="createdAt"
              :formatter="getFormattedDate"
              label="Requested At"
              width="auto"
            >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  key="button"
                  type="text"
                  @click="handleprogTypeRequest(scope, 'approved')"
                >
                  Approve
                </el-button>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  key="button"
                  type="text"
                  @click="handleprogTypeRequest(scope, 'declined')"
                >
                  Decline
                </el-button>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-1" v-show="isSuperAdmin">
      <el-col :span="24" v-show="showPendingProgramTypeApprovals">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Club requesting for approval to access these Program Type Settings </span>
            <el-button
              icon="el-icon-refresh"
              style="float: right; padding: 3px 0"
              type="text"
              @click="getProgTypeRequests"
            >
              Refresh
            </el-button>
          </div>
          <el-alert :closable="false" type="success">
            By clicking "APPROVE," you are confirming that the club can access this program type
            settings and can create programs associated with this type.
          </el-alert>
          <data-tables-server
            v-loading="loading"
            :data="progTypeSettingsRequests"
            :table-props="tableProps"
            :pagination-props="paginationProps"
            :page-size="progTypeSettingsRequestsData.pageSize"
            :current-page.sync="progTypeSettingsRequestsData.page"
            :total="progTypeSettingsRequestsData.count"
            class="data-table"
          >
            <el-table-column prop="requestedClubId" label="Club Id" width="auto"> </el-table-column>
            <el-table-column prop="requestedClubName" label="Club Name" width="auto">
            </el-table-column>
            <el-table-column prop="type" label="Program Type Setting" width="auto">
            </el-table-column>
            <el-table-column
              prop="createdAt"
              :formatter="getFormattedDate"
              label="Requested At"
              width="auto"
            >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  key="button"
                  type="text"
                  @click="handleprogTypeRequest(scope, 'approved')"
                >
                  Approve
                </el-button>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  key="button"
                  type="text"
                  @click="handleprogTypeRequest(scope, 'declined')"
                >
                  Decline
                </el-button>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-1" v-if="isValidAdmin">
      <el-col :span="24" v-show="showUnavailableMembersReport">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Unavailable Members</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleMembers">
              Members Search
            </el-button>
            <el-button
              icon="el-icon-refresh"
              style="float: right; padding: 3px 0"
              type="text"
              @click="handleRefresh('unavailable')"
            >
              Refresh
            </el-button>
          </div>
          <data-tables-server
            v-loading="loading_unavailable"
            :data="unavailable_members"
            :table-props="tableProps"
            :pagination-props="paginationProps"
            :page-size="unavailableMemberSearch.pageSize"
            :current-page.sync="unavailableMemberSearch.page"
            :total="unavailableMemberSearch.count"
            @query-change="queryChange('unavailable')"
            class="data-table"
          >
            <el-table-column prop="_id" label="Member Id" width="auto"> </el-table-column>
            <el-table-column prop="profile.firstName" label="First Name" width="auto">
            </el-table-column>
            <el-table-column prop="profile.lastName" label="Last Name" width="auto">
            </el-table-column>
            <el-table-column label="Age" width="auto">
              <template slot-scope="scope">
                {{ calculateAge(scope.row.profile.dob) }}
              </template>
            </el-table-column>
            <el-table-column prop="available.notes" label="Reason" width="300"> </el-table-column>
            <el-table-column
              prop="available.endDate"
              :formatter="expiryFormatter"
              label="Expiry Date"
              width="150"
            >
            </el-table-column>
            <el-table-column label="Teams" width="175">
              <template slot-scope="scope">
                <ul>
                  <li v-for="team in scope.row.teams" :key="team._id" class="">
                    {{ team.name }}
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button key="button" type="text" @click="handleRowClick(scope)">
                  View
                </el-button>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-card>
      </el-col>
    </el-row>

    <!-- [GRBAU-2251] https://nationalrugbyleague.atlassian.net/browse/GRBAU-2251 -->
    <!-- <div class="chartGroup">
      <el-select
        placeholder="Select"
        @change="setFilter"
        v-model="season"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div id="chartContainer" class="chartContainer"></div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import getChartsJwt from "./httpcharts";
import { roles } from "../../utils/constants";
import { calculateAge } from "../../utils/utilities";
import store from "../../store";

export default {
  name: "Dashboard",
  props: {},
  data() {
    return {
      isRefereeAssociation: false,
      isAssociationAdmin: false,
      isValidAdmin: false,
      roleType: undefined,
      loading: true,
      loading_unavailable: true,
      chart: undefined,
      season: "All",
      options: [
        {
          value: "All",
          label: "All",
        },
        {
          value: 2023,
          label: 2023,
        },
        {
          value: 2022,
          label: 2022,
        },
        {
          value: 2021,
          label: 2021,
        },
        {
          value: 2020,
          label: 2020,
        },
        {
          value: 2019,
          label: 2019,
        },
      ],
      members: [],
      unavailable_members: [],
      memberSearch: {
        page: 1,
        limit: 10,
        count: 0,
      },
      unavailableMemberSearch: {
        page: 1,
        pageSize: 10,
        limit: 10,
        count: 0,
      },
      associationRequests: [],
      associationRequestsData: {
        page: 1,
        limit: 10,
        count: 0,
      },
      progTypeSettingsRequests: [],
      progTypeSettingsRequestsData: {
        page: 1,
        limit: 10,
        count: 0,
      },
      tableProps: {
        border: false,
        compact: true,
      },
      paginationProps: {
        pageSizes: [10, 20, 50, 100],
      },
      showPreferNameApprovals: true,
      showRefereeAssociationRequests: true,
      showUnavailableMembersReport: true,
      calculateAge,
      showPendingProgramTypeApprovals: true,
    };
  },
  methods: {
    handleRefereeRequest(data, action) {
      const associationId = data.row.requestedAssociationId;
      this.$http
        .post(`/nrl/api/v1/admin/associations/referee/request/update`, {
          action: action,
          _id: data.row._id,
          associationId: associationId,
        })
        .then((response) => {
          this.$customSuccess(
            `Successfully ${action} request for ${data.row.requestedAssociationName}`
          );
          this.getRefereeRequests();
        })
        .catch(() => {
          this.$customError();
        });
    },
    handleprogTypeRequest(data, action) {
      this.$confirm("Are you sure you want to Approve [or Decline] this Program?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$http
            .put(`/nrl/api/v1/admin/programs-type-settings/request`, {
              action: action,
              _id: data.row._id,
            })
            .then((response) => {
              this.$customSuccess(
                `Successfully ${action} request for ${data.row.requestedClubName} to access ${data.row.type}`
              );
              this.getProgTypeRequests();
            })
            .catch(() => {
              this.$customError();
            });
        })
        .catch(() => {});
    },

    getFormattedDate(row) {
      return this.moment(row.createdAt).format("DD-MM-YYYY");
    },
    async setFilter(value) {
      this.season = value;
      if (value === "All") {
        await this.chart.setFilter({});
        return;
      }
      await this.chart.setFilter({
        season: value,
      });
      return;
    },
    async embedChart() {
      const chartsApiUrl = this.$store.getters["root/chartsApiUrl"];
      const chartId = this.$store.getters["root/chartId"];

      const sdk = new ChartsEmbedSDK({
        baseUrl: chartsApiUrl,
        getUserToken: async () => await getChartsJwt(),
      });

      this.chart = sdk.createChart({
        chartId: chartId,
        autoRefresh: true,
        maxDataAge: 100,
        showAttribution: false,
        filter: {},
      });
      await this.chart.render(document.getElementById("chartContainer"));
    },
    expiryFormatter(row) {
      return row.available && row.available.endDate
        ? this.moment(row.available.endDate).format("DD/MM/YYYY")
        : "NA";
    },
    teamsFormatter(team) {
      const teamId = team._id;
      this.$router.push(`teams/${teamId}`);
    },
    queryChange(table) {
      if (table === "preferred-name") this.getMembers();
      if (table === "unavailable") this.getUnavailableMembers();
    },
    async getRefereeRequests() {
      this.loading = true;
      if (!this.associationRequestsData.page) {
        return;
      }
      this.$http
        .get(`/nrl/api/v1/admin/associations/referee/requests`)
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.associationRequests = response.data.data;
          this.isRefereeAssociation = response.data.isRefereeAssociation;
          this.associationRequestsData.count =
            response && response.data && response.data.data ? response.data.data.length : 0;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },

    async getProgTypeRequests() {
      this.loading = true;
      const url = "/nrl/api/v1/admin/pending/requested-programs-settings";
      this.$http
        .get(url)
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.progTypeSettingsRequests = response.data.data;
          this.progTypeSettingsRequestsData.count = response.data.data.length;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },

    async getProgTypeRequests() {
      this.loading = true;
      const url = "/nrl/api/v1/admin/pending/requested-programs-settings";
      this.$http
        .get(url)
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.progTypeSettingsRequests = response.data.data;
          this.progTypeSettingsRequestsData.count = response.data.data.length;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },

    async getMembers() {
      this.loading = true;
      if (!this.memberSearch.page) {
        return;
      }
      this.$http
        .get(
          `/nrl/api/v1/admin/members/my-members/action-preferred-names/${this.memberSearch.limit}/${this.memberSearch.page}`
        )
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.members = response.data.data;
          this.memberSearch.count = response.data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    async getUnavailableMembers() {
      this.loading_unavailable = true;
      if (!this.unavailableMemberSearch.page) {
        return;
      }
      this.$http
        .get(
          `/nrl/api/v1/admin/members/my-members/action-unavailable-members/${this.unavailableMemberSearch.limit}/${this.unavailableMemberSearch.page}`
        )
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          const orgtreeCheck = store.getters["user/activeRole"];
          const entity_id = orgtreeCheck.entity._id;
          this.unavailable_members = response.data.data.map((mem) => {
            const filteredTeams = [];
            mem.teams = mem.teams.filter((t) => {
              let result;
              switch (this.roleType) {
                case 1:
                  result = t.orgtree.national && t.orgtree.national._id === entity_id;
                  break;
                case 2:
                  result = t.orgtree.state && t.orgtree.state._id === entity_id;
                  break;
                case 3:
                  result = t.orgtree.region && t.orgtree.region._id === entity_id;
                  break;
                case 4:
                  result = t.orgtree.association && t.orgtree.association._id === entity_id;
                  break;
                case 5:
                  result = t.orgtree.club && t.orgtree.club._id === entity_id;
                  break;
                case 6:
                  result = t._id === entity_id;
                  break;
                default:
                  result = false;
                  break;
              }
              return result;
            });
            return mem;
          });
          this.unavailableMemberSearch.count = response.data.count;
          this.loading_unavailable = false;
        })
        .catch((e) => {
          this.loading_unavailable = false;
          this.$customError("unable to generate full unavailable list data");
        });
    },
    handleMembers() {
      this.$router.push(`member-management/`);
    },
    handleRowClick(member) {
      const memberId = member.row._id;
      this.$router.push(`member-management/${memberId}`);
    },
    handleRefresh(table) {
      if (table === "preferred-name") this.getMembers();
      if (table === "unavailable") this.getUnavailableMembers();
    },
    handleApprove(member) {
      const memberId = member.row._id;
      this.$http
        .put(`/nrl/api/v1/admin/members/my-members/action-preferred-names/${memberId}`)
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.members = response.data.data;
          this.memberSearch.count = response.data.count;
          this.loading = false;
          this.$customSuccess(`Approved preferred name for ${member.row.profile.preferFirstName}`);
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    updateShowPreferNameApprovals(event) {
      this.showPreferNameApprovals = event;
    },
    updateShowRefereeAssociationRequests(event) {
      this.showRefereeAssociationRequests = event;
    },
    updateShowPendingProgramTypeApprovals(event) {
      this.showPendingProgramTypeApprovals = event;
    },
    updateShowUnavailableMembersReport(event) {
      this.showUnavailableMembersReport = event;
    },
  },
  computed: {
    time() {
      return moment("19-12-2019, 5:00:00 pm", "DD-MM-YYYY, h:mm a").format("MMMM Do YYYY, h:mm a");
    },
    isSuperAdmin() {
      return (
        this.$store.getters["user/activeRole"].type === roles.superAdmin &&
        this.$store.getters["user/activeRole"].entity._id === 32
      );
    },
  },
  mounted() {
    // [GRBAU-2251] https://nationalrugbyleague.atlassian.net/browse/GRBAU-2251
    // this.embedChart();
    try {
      const roleType = store.getters["user/activeRole"].type;
      this.roleType = roleType;
      if (roleType === roles.associationAdmin) {
        this.isAssociationAdmin = true;
        this.getRefereeRequests();
      }
      if (roleType <= roles.teamAdmin) {
        this.isValidAdmin = true;
      }
      if (roleType === roles.superAdmin) {
        this.getProgTypeRequests();
      }
    } catch (error) {
      this.$customError();
    }
  },
};
</script>

<style scoped lang="scss">
.chartContainer {
  width: 500px;
  height: 300px;
  margin-top: 1em;
}
.chartGroup {
  margin-top: 2em;
}
.clickable-text {
  cursor: pointer;
  color: #098255;
  font-weight: 500;
  transition: all 0.3s linear;
}
.clickable-text:hover {
  opacity: 0.6;
}
</style>
